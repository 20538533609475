import React from 'react';
import { Form as FormikForm } from 'formik';
import { IonList } from '@ionic/react';

export { FileUpload } from './FileUpload';
export { Input } from './Input';
export { RadioGroup } from './RadioGroup';
export { Select } from './Select';
export { StepDisplay } from './StepDisplay';
export { TextArea } from './TextArea';

export const Form: React.FC<any> = ({ children, className, ...props }) => {
  return (
    <FormikForm {...props} className={className}>
      <IonList lines="full" className={className}>{children}</IonList>
    </FormikForm>
  );
};
