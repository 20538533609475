import React from "react";
import { useTranslation } from 'react-i18next';
import { IonThumbnail, IonItem, IonLabel } from '@ionic/react';
import { ExerciseProps } from "./types";
import { Media } from "components/Media";
import { Tags } from "../../Tags";
import { Tag } from "../../Tag";
import { Icon } from 'components/Icon';

export const Exercise: React.FC<ExerciseProps> = React.memo((
  {
    setIndex,
    setRoundIndex,
    exerciseIndex,
    exerciseRoundIndex,
    // exerciseSet,
    reps,
    kg,
    minutes,
    seconds,
    exercise: {
      title,
      // description,
      thumbnail,
      media,
    },
    exerciseRefs,
    currentExerciseId,
    selectExercise,
  }
) => {
  const { t } = useTranslation();
  const exerciseId = (setIndex + 1) * 100 * 100 * 100 + (setRoundIndex + 1) * 100 * 100 + (exerciseIndex + 1) * 100 + (exerciseRoundIndex + 1);
  const isActive = exerciseId === currentExerciseId;
  const isChecked = exerciseId < (currentExerciseId || 0);

  const handleClick = () => {
    selectExercise(exerciseId);
  }

  return (
    <IonItem
      lines={'full'}
      key={exerciseRoundIndex}
      ref={ref => {
        exerciseRefs.current[exerciseId.toString()] = ref
      }}
      onClick={handleClick}
    >
      <IonThumbnail slot="start">
        <Media aspectRatio={'1:1'} mode={'thumbnail'} thumbnail={thumbnail} media={media}/>
      </IonThumbnail>
      {isActive && <Icon color={'secondary'} name={'radioButtonOnOutline'} slot="end"/>}
      {isChecked && <Icon color={'dark'} name={'checkmarkOutline'} slot="end"/>}
      <IonLabel>
        {!!title && <h3>{title}</h3>}
        {(!!kg || !!minutes || seconds || !!reps) && (
          <Tags className="size-3-padding-bottom">
            {!!kg && <Tag type="weight" value={kg} className={'size-2-margin-start size-3-margin-top'}/>}
            {(!!minutes || !!seconds) && <Tag type="workoutTime" value={(minutes || 0) + ((seconds || 0) / 60)} className={'size-2-margin-start size-3-margin-top'}/>}
            {!!reps && <span className={'size-2-margin-start size-3-margin-top'}>{reps} {t('reps')}</span>}
          </Tags>
        )}
      </IonLabel>
    </IonItem>
  );
});
Exercise.defaultProps = {
  exercise: {}
}
