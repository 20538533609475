import React, {useEffect, useState} from 'react';
import { TimerProps } from './types';
import { IonText } from "@ionic/react";
import { Icon } from 'components/Icon';
import { TimeFormat } from 'components/TimeFormat';
// @ts-ignore
import shortBeep from 'assets/short-beep.wav'
// @ts-ignore
import longBeep from 'assets/long-beep.wav'

let shortBeepAudio = new Audio(shortBeep);
let longBeepAudio = new Audio(longBeep);
document.addEventListener('touchstart', function enableAudio () {
  document.removeEventListener('touchstart', enableAudio);
  try {
    shortBeepAudio.play();
    shortBeepAudio.pause();
    longBeepAudio.play();
    longBeepAudio.pause();
  } catch (e) {}
});

export const Timer: React.FC<TimerProps> = React.memo((
  {
    seconds,
    nextExercise,
    isPaused,
    isMuted,
  }
) => {
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);

  useEffect(() => {
    // shortBeepAudio = new Audio(shortBeep);
    // longBeepAudio = new Audio(longBeep);
    let timeoutID: any;
    if(!isPaused && remainingSeconds > 0) {
      timeoutID = setTimeout(() => {
        setRemainingSeconds(remainingSeconds - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutID);
    }
  }, [isPaused, remainingSeconds, setRemainingSeconds]);

  useEffect(() => {
    if(!isMuted) {
      try {
        if ([3, 2, 1].includes(remainingSeconds)) {
          shortBeepAudio.play().then(() => {
            shortBeepAudio = new Audio(shortBeep)
          });
        }
        if (remainingSeconds === 0) {
          longBeepAudio.play().then();
        }
      } catch (e) {}
    }
  }, [isMuted, remainingSeconds]);

  useEffect(() => {
    if (remainingSeconds === 0) {
      nextExercise();
    }
  }, [remainingSeconds, nextExercise]);

  return (
    <IonText slot={'end'} className={'d-flex ion-align-items-center size-1-padding-end'}>
      <Icon name={isPaused ? 'pauseCircle' : 'stopwatchOutline'} className="size-1-padding-end" />
      <TimeFormat minutes={remainingSeconds / 60} />
    </IonText>
  );
});
