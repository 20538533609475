/*
 * Images
 */
import imagePlaceholder from 'assets/image-placeholder.jpg';
import loading1 from 'assets/loading-1.jpeg';
import loading2 from 'assets/loading-2.jpeg';
import loading3 from 'assets/loading-3.jpeg';
import loading4 from 'assets/loading-4.jpeg';
import loading5 from 'assets/loading-5.jpeg';
import loading6 from 'assets/loading-6.jpeg';

import awardIcon from 'assets/award-solid.svg';

// export const IMAGE_PLACEHOLDER = 'https://gravatar.com/avatar?d=identicon';
export const IMAGE_PLACEHOLDER = imagePlaceholder;

export const LOADING_IMAGES = [
  loading1,
  loading2,
  loading3,
  loading4,
  loading5,
  loading6,
];

/*
 * Icons
 */
export const defaultIconSet = 'ion-icon'; // Defaults to ionIcons

export const icons: { [key: string]: string } = {
  award: awardIcon,
  // rocket: 'flame',
  // rocket: '//cdn.pixabay.com/photo/2020/10/27/18/56/girl-5691576_1280.jpg'
  // rocket: `data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' class=\'ionicon\' viewBox=\'0 0 512 512\'><title>Flame</title><path d=\'M394.23 197.56a300.43 300.43 0 00-53.37-90C301.2 61.65 249.05 32 208 32a16 16 0 00-15.48 20c13.87 53-14.88 97.07-45.31 143.72C122 234.36 96 274.27 96 320c0 88.22 71.78 160 160 160s160-71.78 160-160c0-43.3-7.32-84.49-21.77-122.44zm-105.9 221.13C278 429.69 265.05 432 256 432s-22-2.31-32.33-13.31S208 390.24 208 368c0-25.14 8.82-44.28 17.34-62.78 4.95-10.74 10-21.67 13-33.37a8 8 0 0112.49-4.51A126.48 126.48 0 01275 292c18.17 24 29 52.42 29 76 0 22.24-5.42 39.77-15.67 50.69z\'/></svg>`,
};

export const iosIcons: { [key: string]: string } = {
  // rocket: 'add'
};

export const mdIcons: { [key: string]: string } = {
  // rocket: 'airplane'
};

/*
 * Units
 */
export const WEIGHT_UNIT = 'kg';
