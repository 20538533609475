import styles from './Thumbnail.module.scss';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ThumbnailProps } from './types';

export const Thumbnail: React.FC<ThumbnailProps> = ({ file, width, height, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState(null as string | ArrayBuffer | null);

  useEffect(() => {
    if (!file) {
      return;
    }

    setIsLoading(true);
    let reader = new FileReader();

    reader.onloadend = () => {
      setSrc(reader.result);
      setIsLoading(false);
    };

    reader.readAsDataURL(file);
  }, [file]);

  if (!file) {
    return null;
  }

  if (isLoading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={src ? src.toString() : undefined}
      alt={file.name}
      className={classNames('img-thumbnail mt-2', styles.image, className)}
      width={width || 200}
      height={height || 200}
    />
  );
};
