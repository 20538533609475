import './styles.scss';
import styles from  './Menu.module.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Capacitor } from '@capacitor/core';
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import classNames from 'classnames';
import logoLight from "../../assets/menu-logo-light.png";
import logoDark from "../../assets/menu-logo-dark.png";
import appleStoreBadge from 'assets/apple-store-badge.svg';
import googlePlayStoreBadge from 'assets/google-play-badge.svg';

interface AppPage {
  url: string;
  title: string;
}

export const appPages: AppPage[] = [
  {
    url: '/weekly-workouts',
    title: `This week's workouts`,
  },
  {
    url: '/workouts',
    title: 'Workouts',
  },
  {
    url: '/exercises',
    title: 'Exercises',
  },
  {
    url: '/meals',
    title: 'Meals',
  },
  {
    url: '/tips',
    title: 'Health tips',
  },
  {
    url: '/trainer',
    title: 'Your trainer',
  },
];

export const Menu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isNativePlatform = Capacitor.isNativePlatform();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <nav className={classNames(styles.container, 'safe-padding')}>
          <IonList id="inbox-list">
            <IonListHeader>
              <img src={logoDark} alt="" height="120" className={classNames(styles.logo, styles.lightTheme, 'size-5-margin-bottom')} />
              <img src={logoLight} alt="" height="120" className={classNames(styles.logo, styles.darkTheme, 'size-5-margin-bottom')} />
            </IonListHeader>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === appPage.url ? 'selected' : ''
                    }
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonLabel>{t(appPage.title).toUpperCase()}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
          {!isNativePlatform && (
            <div>
              <a className={styles.storeLink} href={process.env.REACT_APP_APPLE_STORE_URL || ''}>
                <img src={appleStoreBadge} alt="Download on the App Store"/>
              </a>
              <a className={styles.storeLink} href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL || ''}>
                <img src={googlePlayStoreBadge} alt='Get it on Google Play'/>
              </a>
            </div>
          )}
        </nav>
      </IonContent>
    </IonMenu>
  );
};
