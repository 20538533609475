import styles from './TermsPage.module.scss';
import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';

const TermsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage className={'safe-padding'}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {t('Terms and Conditions')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.container}>
        <div className={'size-5-padding-horizontal body max-width'}>
          <div className='div-block-37'>
            <div className='rt-info w-richtext'>
              <p>
                This page explains the terms and conditions by which you may use our Mobile
                Applications (as defined below) and any and all related websites, online and/or mobile services, and
                software
                (collectively, the “Service”) owned and operated by Web Wizard Solutions SRL (“Web Wizard Solutions”, “we”, “our”, or “us”). By
                downloading, accessing or using the Service, or by clicking a button or checking a box marked “I Agree” (or
                something similar), you signify that you have read, understood, and agree to be bound by these Terms of
                Service
                (this “Agreement”) and to the collection and use of your information as set forth in our Privacy Policy,
                whether
                or not you are a registered user of our Service. Web Wizard Solutions reserves the right to modify these terms and will
                provide notice of these changes as described below. This Agreement applies to all visitors, users, and
                others who
                access the Service (“Users”).
              </p>
              <p>
                Please read this Agreement carefully to ensure that you understand each
                provision. This agreement contains a mandatory individual arbitration and class action/jury trial waiver
                provision
                that requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or
                class
                actions.
              </p>

              <h2>1. OUR SERVICE</h2><p>Web Wizard Solutions provides patients (“Patients”) the opportunity to record
              conversations with their medical professionals to share with their friends and family.</p><h3>1.1.
              ELIGIBILITY</h3><p>This is a contract between you and Web Wizard Solutions. You must read and agree to this Agreement
              before
              using the Service. If you do not agree, you may not use the Service. You may use the Service only if you can
              form
              a binding contract with Web Wizard Solutions, and only in compliance with this Agreement and all applicable local, state,
              national, and international laws, rules and regulations. Any use or access to the Service by anyone under 13
              is
              strictly prohibited and in violation of this Agreement. The Service is not available to any Users previously
              removed from the Service by Web Wizard Solutions.</p><h3>1.2. LIMITED LICENSE</h3><p>Subject to the terms and conditions
              of
              this Agreement, you are hereby granted a non-exclusive, limited, non-transferable, freely revocable license to
              use
              the Service for your personal, noncommercial use only and only as permitted by the features of the Service.
              Web Wizard Solutions reserves all rights not expressly granted herein in the Service and the Web Wizard Solutions Content (as
              defined
              below). Web Wizard Solutions may terminate this license at any time for any reason or no reason.</p><h3>1.3.
              NON-PROFESSIONAL
              USE</h3><p>The Service is intended for use by Patients in their personal capacities to communicate information
              about their visits with their Doctors to their friends and family. Notwithstanding anything to the contrary in
              this Agreement, you may not use the Service if you are a medical professional using the Service in your
              professional capacity, including (without limitation) sending diagnostic information to your patients or
              coordinating medical care with other medical professionals. If you are a medical professional, you may only
              use
              the Service either as a Patient sending information to your friends and family or as a friend or family member
              of
              a Patient. Any use of the Service by medical professionals in their professional capacities is a violation of
              this
              Agreement and will result in the automatic termination of the license included in Section 1.2 and the medical
              professional’s access to the Service.</p><h3>1.4. USER ACCOUNTS</h3><p>Your account on the Service (your “User
              Account”) gives you access to the services and functionality that we may establish and maintain from time to
              time
              and in our sole discretion. We may maintain different types of User Accounts for different types of Users. By
              connecting to Web Wizard Solutions with a third-party service, you give us permission to access and use your information
              from
              that service as permitted by that service, and to store your log-in credentials for that service.</p><p>You
              may
              never use another User’s User Account without permission. When creating your User Account, you must provide
              accurate and complete information, and you must keep this information up to date. You are solely responsible
              for
              the activity that occurs on your User Account, and you must keep your User Account password secure. We
              encourage
              you to use “strong” passwords (passwords that use a combination of upper and lower case letters, numbers and
              symbols) with your User Account. You must notify Web Wizard Solutions immediately of any breach of security or
              unauthorized
              use of your User Account. Web Wizard Solutions will not be liable for any losses caused by any unauthorized use of your
              User
              Account.</p><p>You may control your User profile and how you interact with the Service by changing the
              settings in
              your settings page. By providing Web Wizard Solutions your email address you consent to our using the email address to
              send
              you Service-related notices, including any notices required by law, in lieu of communication by postal mail.
              We
              may also use your email address to send you other messages, such as changes to features of the Service and
              special
              offers. If you do not want to receive such email messages, you may opt out or change your preferences in your
              settings page. Opting out may prevent you from receiving email messages regarding updates, improvements, or
              offers.</p><h3>1.5. SERVICE RULES</h3><p>You agree not to engage in any of the following prohibited
              activities:
              (i) using the Service in your professional capacity as a medical professional; (ii) copying, distributing, or
              disclosing any part of the Service in any medium, including without limitation by any automated or
              non-automated
              “scraping”; (iii) using any automated system, including without limitation “robots,” “spiders,” “offline
              readers,”
              etc., to access the Service in a manner that sends more request messages to the Company servers than a human
              can
              reasonably produce in the same period of time by using a conventional on-line web browser (except that Company
              grants the operators of public search engines revocable permission to use spiders to copy publicly available
              materials from the Company Site for the sole purpose of and solely to the extent necessary for creating
              publicly
              available searchable indices of the materials, but not caches or archives of such materials); (iv)
              transmitting
              spam, chain letters, or other unsolicited email; (v) attempting to interfere with, compromise the system
              integrity
              or security or decipher any transmissions to or from the servers running the Service; (vi) taking any action
              that
              imposes, or may impose at our sole discretion an unreasonable or disproportionately large load on our
              infrastructure; (vii) uploading invalid data, viruses, worms, or other software agents through the Service;
              (viii)
              collecting or harvesting any personally identifiable information, including account names, from the Service;
              (ix)
              using the Service for any commercial solicitation purposes; (x) impersonating another person or otherwise
              misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your
              identity; (xi) interfering with the proper working of the Service; (xii) accessing any content on the Service
              through any technology or means other than those provided or authorized by the Service; or (xiii) bypassing
              the
              measures we may use to prevent or restrict access to the Service, including without limitation features that
              prevent or restrict use or copying of any content or enforce limitations on use of the Service or the content
              therein.</p><h3>1.6. CHANGES TO THE SERVICE</h3><p>We may, without prior notice, change the Service; stop
              providing the Service or features of the Service, to you or to Users generally; or create usage limits for the
              Service. We may permanently or temporarily terminate or suspend your access to the Service without notice and
              liability for any reason, including if in our sole determination you violate any provision of this Agreement,
              or
              for no reason. Upon termination for any reason or no reason, you continue to be bound by this Agreement.</p>
              <h3>1.7. DISPUTES WITH OTHER USERS</h3><p>You are solely responsible for your interactions with other Users.
              We
              reserve the right, but have no obligation, to monitor disputes between you and other Users. Web Wizard Solutions shall
              have
              no liability for your interactions with other Users, or for any User’s action or inaction.</p><h3>1.8. SERVICE
              LOCATION</h3><p>The Service is controlled and operated from facilities in the United States. Web Wizard Solutions makes
              no
              representations that the Service is appropriate or available for use in other locations. Those who access or
              use
              the Service from other jurisdictions do so at their own volition and are entirely responsible for compliance
              with all applicable United States and local laws and regulations, including but not limited to export and
              import
              regulations. You may not use the Service if you are a resident of a country embargoed by the United States, or
              are a foreign person or entity blocked or denied by the United States government. Unless otherwise explicitly
              stated, all materials found on the Service are solely directed to individuals, companies, or other entities
              located in the United States.</p><h2>2. USER CONTENT</h2><p>Some areas of the Service allow Users to submit,
              post, display, provide, or otherwise make available content such as profile information, videos, images,
              music,
              comments, questions, text, graphics, symptoms, treatments, dosages, outcomes, charts, profiles, photographs,
              advice, messages and other content or information (any such materials a User submits, posts, displays,
              provides,
              or otherwise makes available on the Service is referred to as “User Content”).</p><p>We claim no ownership
              rights over User Content created by you. The User Content you create remains yours. However, you understand
              that
              certain portions of the Service may allow other Users to view, edit, share, and/or otherwise interact with
              your
              User Content. By providing or sharing User Content through the Service, you agree to allow others to view,
              edit,
              share, and/or interact with your User Content in accordance with your settings and this Agreement. Web Wizard Solutions
              has
              the right (but not the obligation) in its sole discretion to remove any User Content that is shared via the
              Service.</p><p>By submitting, posting, displaying, providing, or otherwise making available any User Content
              on
              or through the Service, you expressly grant, and you represent and warrant that you have all rights necessary
              to
              grant, to Web Wizard Solutions a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive,
              worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute,
              syndicate, publicly perform, publicly display, and make derivative works of all such User Content and your
              name,
              voice, and/or likeness as contained in your User Content, in whole or in part, and in any form, media or
              technology, whether now known or hereafter developed, for use in connection with the Service and Web Wizard Solutions’s
              (and its successors’ and affiliates’) business, including without limitation for promoting and redistributing
              part or all of the Service (and derivative works thereof) in any media formats and through any media channels.
              You also hereby grant each User you have chosen to share information with a non-exclusive license to access
              your
              User Content through the Service, and to use, reproduce, distribute, display and perform such User Content as
              permitted through the functionality of the Service and under this Agreement.</p><p>For the purposes of this
              Agreement, “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and
              other intellectual property rights as may now exist or hereafter come into existence, and all applications
              therefore and registrations, renewals and extensions thereof, under the laws of any state, country, territory
              or
              other jurisdiction.</p><p>In connection with your User Content, you affirm, represent and warrant the
              following:</p>
              <ul>
                <li>You have the written consent of each and every identifiable natural person in the User Content, if any,
                  to
                  use such person’s name or likeness in the manner contemplated by the Service and this Agreement, and each
                  such
                  person has released you from any liability that may arise in relation to such use.
                </li>
                <li>You have obtained and are solely responsible for obtaining all consents as may be required by law to
                  post
                  any User Content relating to third parties.
                </li>
                <li>Your User Content and Web Wizard Solutions’s use thereof as contemplated by this Agreement and the Service will not
                  violate any law or infringe any rights of any third party, including but not limited to any Intellectual
                  Property Rights and privacy rights.
                </li>
                <li>Web Wizard Solutions may exercise the rights to your User Content granted under this Agreement without liability
                  for
                  payment of any guild fees, residuals, payments, fees, or royalties payable under any collective bargaining
                  agreement or otherwise.
                </li>
                <li>To the best of your knowledge, all your User Content and other information that you provide to us is
                  truthful and accurate.
                </li>
              </ul>
              <p>Web Wizard Solutions takes no responsibility and assumes no liability for any User Content that you or any other User
                or
                third party posts, sends, or otherwise makes available over the Service. You shall be solely responsible for
                your User Content and the consequences of posting, publishing it, sharing it, or otherwise making it
                available
                on the Service, and you agree that we are only acting as a passive conduit for your online distribution and
                publication of your User Content. You understand and agree that you may be exposed to User Content that is
                inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose, and you agree
                that
                Web Wizard Solutions shall not be liable for any damages you allege to incur as a result of or relating to any User
                Content.</p><h2>3. OUR PROPRIETARY RIGHTS</h2><p>Except for your User Content, the Service and all materials
              therein or transferred thereby, including, without limitation, software, images, text, graphics,
              illustrations,
              logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and User Content
              belonging to other Users (the “Web Wizard Solutions Content”), and all Intellectual Property Rights related thereto, are
              the exclusive property of Web Wizard Solutions and its licensors (including other Users who post User Content to the
              Service). Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license
              in
              or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute,
              copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works
              from any Web Wizard Solutions Content. Use of the Web Wizard Solutions Content for any purpose not expressly permitted by this
              Agreement is strictly prohibited.</p><p>You may choose to or we may invite you to submit comments or ideas
              about
              the Service, including without limitation about how to improve the Service or our products (“Ideas”). By
              submitting any Idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and
              will
              not place Web Wizard Solutions under any fiduciary or other obligation, and that we are free to use the Idea without any
              additional compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to
              anyone.
              You further acknowledge that, by acceptance of your submission, Web Wizard Solutions does not waive any rights to use
              similar or related ideas previously known to Web Wizard Solutions, or developed by its employees, or obtained from
              sources
              other than you.</p><h2>4. NO PROFESSIONAL OR DIAGNOSTIC ADVICE</h2><p>Any information submitted, posted,
              displayed, provided, or otherwise made available on the Service is for informational, research, and
              educational
              purposes only. It is not intended to be used to diagnose, cure, treat, or prevent any condition or diseases.
              No
              action should be taken based upon any information contained in the Service (including, without limitation, any
              User Content and Web Wizard Solutions Content). You should seek independent professional and medical advice from a person
              who is licensed and/or qualified in the applicable area.</p><h2>5. PRIVACY</h2><p>We care about the privacy of
              our Users. You understand that by using the Service you consent to the collection, use and disclosure of your
              personally identifiable information and aggregate and/or anonymized data as set forth in our Privacy
              Policy.</p>
              <h2>6. RECORDINGS</h2><p>You agree to comply with all applicable laws, whether federal, state, local or
              international, relating to the privacy of communication for all parties to a conversation, including without
              limitation, advising all participants in a recorded conversation that the conversation is being recorded and
              obtaining their consent, as required.</p><h2>7. SECURITY</h2><p>Web Wizard Solutions cares about the integrity and
              security
              of your personal information; however, we cannot guarantee that unauthorized third parties will never be able
              to
              defeat our security measures or use your personal information for improper purposes. You acknowledge that you
              provide your personal information at your own risk.',</p><h2>8. DMCA NOTICE</h2><p>Since we respect artist and
              content owner rights, it is Web Wizard Solutions’s policy to respond to alleged infringement notices that comply with the
              Digital Millennium Copyright Act of 1998 (“DMCA”).</p><p>If you believe that your copyrighted work has been
              copied in a way that constitutes copyright infringement and is accessible via the Service, please notify
              Web Wizard Solutions’s copyright agent as set forth in the DMCA. For your complaint to be valid under the DMCA, you must
              provide the following information in writing:</p><p>8.1. An electronic or physical signature of a person
              authorized to act on behalf of the copyright owner;<br />8.2. Identification of the copyrighted work that you
              claim has been infringed;<br />8.3. Identification of the material that is claimed to be infringing and where
              it
              is located on the Service;<br />8.4. Information reasonably sufficient to permit Web Wizard Solutions to contact you,
              such as your address, telephone number, and, e-mail address;<br />8.5. A statement that you have a good
              faith belief that use of the material in the manner complained of is not authorized by the copyright
              owner, its agent, or law;<br />8.6. A statement, made under penalty of perjury, that the above
              information
              is accurate, and that you are the copyright owner or are authorized to act on behalf of the
              owner.<br />The
              above information must be submitted to the following DMCA Agent:</p><p>Attn: DMCA Notice<br />Web Wizard Solutions SRL
              <br />Calea 13 Septembrie 59-61, Bucharest, Romania<br />Tel.: +40-725-422-737<br />Email: <a
                href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a></p><p>Under federal law, if you knowingly
              misrepresent that online material is infringing, you may be subject to criminal prosecution for perjury and
              civil penalties, including monetary damages, court costs, and attorneys’ fees.</p><p>Please note that this
              procedure is exclusively for notifying Web Wizard Solutions and its affiliates that your copyrighted material has been
              infringed. The preceding requirements are intended to comply with Web Wizard Solutions’s rights and obligations under the
              DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact an
              attorney regarding your rights and obligations under the DMCA and other applicable laws.</p><p>In accordance
              with the DMCA and other applicable law, Web Wizard Solutions has adopted a policy of terminating, in appropriate
              circumstances, Users who are deemed to be repeat infringers. Web Wizard Solutions may also at its sole discretion limit
              access to the Service and/or terminate the User Accounts of any Users who infringe any intellectual property
              rights of others, whether or not there is any repeat infringement.</p><h2>9. THIRD-PARTY LINKS AND
              INFORMATION</h2><p>The Service may contain links to third-party materials that are not owned or controlled by
              Web Wizard Solutions. Web Wizard Solutions does not endorse or assume any responsibility for any such third-party sites,
              information,
              materials, products, or services. If you access a third-party website or service from the Service or share
              your
              User Content on or through any third-party website or service, you do so at your own risk, and you understand
              that this Agreement and Web Wizard Solutions’s Privacy Policy do not apply to your use of such sites. You expressly
              relieve
              Web Wizard Solutions from any and all liability arising from your use of any third-party website, service, or content,
              including without limitation User Content submitted by other Users. Additionally, your dealings with or
              participation in promotions of advertisers found on the Service, including payment and delivery of goods, and
              any other terms (such as warranties) are solely between you and such advertisers. You agree that Web Wizard Solutions
              shall
              not be responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>
              <h2>10. INDEMNITY</h2><p>You agree to defend, indemnify and hold harmless Web Wizard Solutions and its subsidiaries,
              agents,
              licensors, managers, and other affiliated companies, and their employees, contractors, agents, officers and
              directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
              expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to the
              Service,
              including any data or content transmitted or received by you; (ii) your violation of any term of this
              Agreement,
              including without limitation your breach of any of the representations and warranties above; (iii) your
              violation of any third-party right, including without limitation any right of privacy or Intellectual Property
              Rights; (iv) your violation of any applicable law, rule or regulation; (v) User Content or any content that is
              submitted via your User Account including without limitation misleading, false, or inaccurate information;
              (vi)
              your willful misconduct; or (vii) any other party’s access and use of the Service with your unique username,
              password or other appropriate security code.</p><h2>11. NO WARRANTY</h2><p>The Service is provided on an “as
              is”
              and “as available” basis. Use of the Service is at your own risk. To the maximum extent permitted by
              applicable
              law, the Service is provided without warranties of any kind, whether express or implied, including, but not
              limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. No
              advice or information, whether oral or written, obtained by you from Web Wizard Solutions or through the Service will
              create any warranty not expressly stated herein. Without limiting the foregoing, Web Wizard Solutions, its subsidiaries,
              its affiliates, and its licensors do not warrant that the content is accurate, reliable or correct; that the
              Service will meet your requirements; that the Service will be available at any particular time or location,
              uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses
              or
              other harmful components. Any content downloaded or otherwise obtained through the use of the Service is
              downloaded at your own risk and you will be solely responsible for any damage to your computer system or
              mobile
              device or loss of data that results from such download or your use of the Service.</p><p>Further, Web Wizard Solutions
              does
              not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by
              a
              third party through the Service or any hyperlinked website or service, and Web Wizard Solutions will not be a party to or
              in any way monitor any transaction between you and third-party providers of products or services.</p><p>The
              Service is not intended to (and does not) create any patient relationship between Web Wizard Solutions and you, nor
              should
              it be considered a replacement for consultation with a health care professional. You should never disregard
              medical advice or delay seeking medical advice because of something you have read on the Service.</p>
              <p>Federal
                law, some states, provinces and other jurisdictions do not allow the exclusion and limitations of certain
                implied warranties, so the above exclusions may not apply to you. This Agreement gives you specific legal
                rights, and you may also have other rights which vary from state to state. The disclaimers and exclusions
                under
                this Agreement will not apply to the extent prohibited by applicable law.</p><h2>12. LIMITATION OF
              LIABILITY</h2><p>To the maximum extent permitted by applicable law, in no event shall Web Wizard Solutions, its
              affiliates,
              agents, directors, employees, suppliers or licensors be liable for any indirect, punitive, incidental,
              special,
              consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use,
              data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service.
              Under no circumstances will Web Wizard Solutions be responsible for any damage, loss or injury resulting from hacking,
              tampering or other unauthorized access or use of the Service or your account or the information contained
              therein.</p><p>To the maximum extent permitted by applicable law, Web Wizard Solutions assumes no liability or
              responsibility for any (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or property
              damage, of any nature whatsoever, resulting from your access to, use of, or reliance on our service and any
              and
              all information contained therein (including any user content or Web Wizard Solutions content); (iii) any unauthorized
              access to or use of our secure servers and/or any and all personal information stored therein; (iv) any
              interruption or cessation of transmission to or from the Service; (v) any bugs, viruses, trojan horses, or the
              like that may be transmitted to or through our service by any third party; (vi) any errors or omissions in any
              content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted,
              or otherwise made available through the Service; and/or (vii) User Content or the defamatory, offensive, or
              illegal conduct of any third party. In no event shall Web Wizard Solutions, its affiliates, agents, directors, employees,
              suppliers, or licensors be liable to you for any claims, proceedings, liabilities, obligations, damages,
              losses
              or costs in an amount exceeding the amount you paid to Web Wizard Solutions hereunder or $100.00, whichever is greater.',
              'This limitation of liability section applies whether the alleged liability is based on contract, tort,
              negligence, strict liability, or any other basis, even if Web Wizard Solutions has been advised of the possibility of
              such
              damage.', 'Some states do not allow the exclusion or limitation of incidental or consequential damages, so the
              above limitations or exclusions may not apply to you. This Agreement gives you specific legal rights, and you
              may also have other rights which vary from state to state. The disclaimers, exclusions, and limitations of
              liability under this Agreement will not apply to the extent prohibited by applicable law.</p><h2>13. GOVERNING
              LAW, ARBITRATION, AND CLASS ACTION/JURY TRIAL WAIVER</h2><h3>13.1 GOVERNING LAW</h3><p>You agree that: (i) the
              Service shall be deemed solely based in California; and (ii) the Service shall be deemed a passive one that
              does
              not give rise to personal jurisdiction over us, either specific or general, in jurisdictions other than
              California. This Agreement shall be governed by the internal substantive laws of the State of California,
              without respect to its conflict of laws principles. The parties acknowledge that this Agreement evidences a
              transaction involving interstate commerce. Notwithstanding the preceding sentences with respect to the
              substantive law, any arbitration conducted pursuant to the terms of this Agreement shall be governed by the
              Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United Nations Convention on Contracts for
              the International Sale of Goods is expressly excluded. You agree to submit to the personal jurisdiction of the
              federal and state courts located in San Mateo County, California for any actions for which we retain the right
              to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or
              threatened infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets,
              patents,
              or other intellectual property or proprietary rights, as set forth in the Arbitration provision below,
              including
              any provisional relief required to prevent irreparable harm. You agree that San Mateo County, California is
              the
              proper forum for any appeals of an arbitration award or for trial court proceedings in the event that the
              arbitration provision below is found to be unenforceable.</p><h3>13.2 ARBITRATION.</h3><p>Read this section
              carefully because it requires the parties to arbitrate their disputes and limits the manner in which you can
              seek relief from Web Wizard Solutions. For any dispute with Web Wizard Solutions, you agree to first contact us at <a
                href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a> and attempt to resolve the dispute with us
              informally. In the unlikely event that Web Wizard Solutions has not been able to resolve a dispute it has with you after
              sixty (60) days, we each agree to resolve any claim, dispute, or controversy (excluding any claims for
              injunctive or other equitable relief as provided below) arising out of or in connection with or relating to
              this
              Agreement, or the breach or alleged breach thereof (collectively, “Claims”), by binding arbitration by JAMS,
              under the Optional Expedited Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS
              may
              be contacted at <a href='http://www.jamsadr.com/'>www.jamsadr.com</a>. The arbitration will be conducted in
              San
              Mateo County, California, unless you and Web Wizard Solutions agree otherwise. Any judgment on the award rendered by the
              arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall be deemed as
              preventing Web Wizard Solutions from seeking injunctive or other equitable relief from the courts as necessary to prevent
              the actual or threatened infringement, misappropriation, or violation of our data security, Intellectual
              Property Rights or other proprietary rights.</p><h3>13.3 CLASS ACTION/JURY TRIAL WAIVER.</h3><p>With respect
              to
              all persons and entities, regardless of whether they have obtained or used the Service for personal,
              commercial
              or other purposes, all Claims must be brought in the parties’ individual capacity, and not as a plaintiff or
              class member in any purported class action, collective action, private attorney general action or other
              representative proceeding. This waiver applies to class arbitration, and, unless we agree otherwise, the
              arbitrator may not consolidate more than one person’s Claims. You agree that, by entering into this Agreement,
              you and Web Wizard Solutions are each waiving the right to a trial by jury or to participate in a class action,
              collective
              action, private attorney general action, or other representative proceeding of any kind.</p><h2>14. ADDITIONAL
              TERMS FOR MOBILE APPLICATIONS</h2><h3>14.1 MOBILE APPLICATIONS.</h3><p>We may make available software to
              access
              the Service via a mobile device (“Mobile Applications”). To use any Mobile Applications you must have a mobile
              device that is compatible with the Mobile Applications. Web Wizard Solutions does not warrant that the Mobile
              Applications
              will be compatible with your mobile device. You may use mobile data in connection with the Mobile Applications
              and may incur additional charges from your wireless provider for these services. You agree that you are solely
              responsible for any such charges. Web Wizard Solutions hereby grants you a non-exclusive, non-transferable, revocable
              license to use a compiled code copy of the Mobile Applications for one Web Wizard Solutions User Account on one mobile
              device owned or leased solely by you, for your personal use. You may not: (i) modify, disassemble, decompile
              or
              reverse engineer the Mobile Applications, except to the extent that such restriction is expressly prohibited
              by
              law; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Mobile Applications to
              any
              third party or use the Mobile Applications to provide time sharing or similar services for any third party;
              (iii) make any copies of the Mobile Applications; (iv) remove, circumvent, disable, damage or otherwise
              interfere with security-related features of the Mobile Applications, features that prevent or restrict use or
              copying of any content accessible through the Mobile Applications, or features that enforce limitations on use
              of the Mobile Applications; or (v) delete the copyright and other proprietary rights notices on the Mobile
              Applications. You acknowledge that Web Wizard Solutions may from time to time issue upgraded versions of the Mobile
              Applications, and may automatically electronically upgrade the version of the Mobile Applications that you are
              using on your mobile device. You consent to such automatic upgrading on your mobile device, and agree that the
              terms and conditions of this Agreement will apply to all such upgrades. Any third-party code that may be
              incorporated in the Mobile Applications is covered by the applicable open source or third-party license EULA,
              if
              any, authorizing use of such code. The foregoing license grant is not a sale of the Mobile Applications or any
              copy thereof, and Web Wizard Solutions or its third-party partners or suppliers retain all right, title, and interest in
              the Mobile Applications (and any copy thereof). Any attempt by you to transfer any of the rights, duties or
              obligations hereunder, except as expressly provided for in this Agreement, is void. Web Wizard Solutions reserves all
              rights not expressly granted under this Agreement. If the Mobile Applications is being acquired on behalf of
              the
              United States Government, then the following provision applies. The Mobile Applications will be deemed to be
              “commercial computer software” and “commercial computer software documentation,” respectively, pursuant to
              DFAR
              Section 227.7202 and FAR Section 12.212, as applicable. Any use, reproduction, release, performance, display
              or
              disclosure of the Service and any accompanying documentation by the U.S. Government will be governed solely by
              these Terms of Service and is prohibited except to the extent expressly permitted by these Terms of Service.
              The
              Mobile Applications originates in the United States, and is subject to United States export laws and
              regulations. The Mobile Applications may not be exported or re-exported to certain countries or those persons
              or
              entities prohibited from receiving exports from the United States. In addition, the Mobile Applications may be
              subject to the import and export laws of other countries. You agree to comply with all United States and
              foreign
              laws related to use of the Mobile Applications and the Service.</p><h3>14.2 MOBILE APPLICATIONS FROM APPLE APP
              STORE.</h3><p>The following applies to any Mobile Applications you acquire from the Apple App Store
              (“Apple-Sourced Software”): You acknowledge and agree that this Agreement is solely between you and Web Wizard Solutions,
              not Apple, Inc. (“Apple”) and that Apple has no responsibility for the Apple-Sourced Software or content
              thereof. Your use of the Apple-Sourced Software must comply with the App Store Terms of Service. You
              acknowledge
              that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the
              Apple-Sourced Software. In the event of any failure of the Apple-Sourced Software to conform to any applicable
              warranty, you may notify Apple, and Apple will refund the purchase price for the Apple-Sourced Software to
              you;
              to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever
              with
              respect to the Apple-Sourced Software, and any other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty will be solely governed by this Agreement and any law
              applicable to Web Wizard Solutions as provider of the software. You acknowledge that Apple is not responsible for
              addressing any claims of you or any third party relating to the Apple-Sourced Software or your possession
              and/or
              use of the Apple-Sourced Software, including, but not limited to: (i) product liability claims; (ii) any claim
              that the Apple-Sourced Software fails to conform to any applicable legal or regulatory requirement; and (iii)
              claims arising under consumer protection or similar legislation; and all such claims are governed solely by
              this
              Agreement and any law applicable to Web Wizard Solutions as provider of the software. You acknowledge that, in the event
              of
              any third-party claim that the Apple-Sourced Software or your possession and use of that Apple-Sourced
              Software
              infringes that third party’s intellectual property rights, Web Wizard Solutions, not Apple, will be solely responsible
              for
              the investigation, defense, settlement and discharge of any such intellectual property infringement claim to
              the
              extent required by this Agreement. You and Web Wizard Solutions acknowledge and agree that Apple, and Apple’s
              subsidiaries,
              are third-party beneficiaries of this Agreement as relates to your license of the Apple-Sourced Software, and
              that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will
              be
              deemed to have accepted the right) to enforce this Agreement as relates to your license of the Apple-Sourced
              Software against you as a third-party beneficiary thereof.</p><h3>14.3 MOBILE APPLICATIONS FROM GOOGLE PLAY
              STORE.</h3><p>The following applies to any Mobile Applications you acquire from the Google Play Store
              (“Google-Sourced Software”): (i) you acknowledge that the Agreement is between you and Web Wizard Solutions only, and not
              with Google, Inc. (“Google”); (ii) your use of Google-Sourced Software must comply with Google’s then-current
              Google Play Store Terms of Service; (iii) Google is only a provider of the Google Play Store where you
              obtained
              the Google-Sourced Software; (iv) Web Wizard Solutions, and not Google, is solely responsible for its Google-Sourced
              Software; (v) Google has no obligation or liability to you with respect to Google-Sourced Software or the
              Agreement; and (vi) you acknowledge and agree that Google is a third-party beneficiary to the Agreement as it
              relates to Web Wizard Solutions’s Google-Sourced Software.</p><h2>15. PERMISSIONS</h2><p>The Service includes certain
              functions that are accessed or modified through Mobile Applications, which may require explicit user
              permission
              to access such functions. You acknowledge and agree that denying explicit user permission to those function
              may
              affect or reduce your user experience, or prevent the Mobile Application from working altogether. Those
              functions may include access to contacts, microphone, or camera functions.</p><h2>16. GENERAL</h2><h3>16.1
              ASSIGNMENT.</h3><p>This Agreement, and any rights and licenses granted hereunder, may not be transferred or
              assigned by you, but may be assigned by Web Wizard Solutions without restriction. Any attempted transfer or assignment in
              violation hereof shall be null and void.</p><h3>16.2 NOTIFICATION PROCEDURES AND CHANGES TO THE
              AGREEMENT.</h3>
              <p>Web Wizard Solutions may provide notifications, whether such notifications are required by law or are for marketing or
                other business related purposes, to you via email notice, written or hard copy notice, or through posting of
                such notice on our website, as determined by Web Wizard Solutions in our sole discretion. Web Wizard Solutions reserves the right
                to
                determine the form and means of providing notifications to our Users, provided that you may opt out of
                certain
                means of notification as described in this Agreement. Web Wizard Solutions is not responsible for any automatic
                filtering
                you or your network provider may apply to email notifications we send to the email address you provide us.
                Web Wizard Solutions may, in its sole discretion, modify or update this Agreement from time to time, and so you should
                review this page periodically. When we change the Agreement in a material manner, we will update the ‘last
                modified’ date at the top of this page and notify you that material changes have been made to the Agreement.
                Your continued use of the Service after any such change constitutes your acceptance of the new Terms of
                Service.
                If you do not agree to any of these terms or any future Terms of Service, do not use or access (or continue
                to
                access) the Service.</p><h3>16.3 ENTIRE AGREEMENT/SEVERABILITY.</h3><p>This Agreement, together with any
              amendments and any additional agreements you may enter into with Web Wizard Solutions in connection with the Service,
              shall
              constitute the entire agreement between you and Web Wizard Solutions concerning the Service. If any provision of this
              Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not
              affect the validity of the remaining provisions of this Agreement, which shall remain in full force and
              effect,
              except that in the event of unenforceability of the universal Class Action/Jury Trial Waiver, the entire
              arbitration agreement shall be unenforceable.</p><h3>16.4 NO WAIVER.</h3><p>No waiver of any term of this
              Agreement shall be deemed a further or continuing waiver of such term or any other term, and Web Wizard Solutions’s
              failure
              to assert any right or provision under this Agreement shall not constitute a waiver of such right or
              provision.</p><p>16.5 Contact. Please contact us at <a
              href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a> with any questions regarding this Agreement.<br />
            </p>
            </div>
          </div>
        </div>
        <Footer/>
      </IonContent>
    </IonPage>
  );
};

export default TermsPage;