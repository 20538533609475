import React from 'react';
import { useTranslation } from 'react-i18next';
import { WeightFormatProps } from './types';
import { WEIGHT_UNIT } from 'theme/config';
import { NumberFormat } from '../NumberFormat';

export const WeightFormat: React.FC<WeightFormatProps> = ({
  kg,
  unit = WEIGHT_UNIT || 'kg',
  className,
}) => {
  const { t } = useTranslation();
  if (typeof kg === 'undefined') return null;
  const parsedValue = typeof kg === 'string' ? parseFloat(kg) : kg;
  let convertedValue = unit === 'pound' ? parsedValue * 2.20462 : parsedValue;

  return (
    <span className={className}>
      <NumberFormat
        value={convertedValue}
        maximumFractionDigits={1}
        className="size-1-padding-end"
      />
      {unit === 'pound' ? t('pound') : t('kg')}
    </span>
  );
};
