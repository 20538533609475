import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeFormatProps } from './types';

export const TimeFormat: React.FC<TimeFormatProps> = ({
  minutes = 0,
  convertToString = false,
  className,
}) => {
  const { t } = useTranslation();

  if (typeof minutes === 'undefined') return null;
  const parsedMinutes =
    typeof minutes === 'string' ? parseFloat(minutes) : minutes;
  const totalSeconds = Math.round(parsedMinutes * 60);
  const s = totalSeconds % 60;
  const m = ((totalSeconds - s) % (60 * 60)) / 60;
  const h = (totalSeconds - s - m * 60) / (60 * 60);

  let result = '';
  if (h > 0) {
    result = `${h}:${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`;
  } else if (m > 0) {
    result = `${m}:${s.toString().padStart(2, '0')}`;
  } else {
    result = `:${s.toString().padStart(2, '0')}`;
  }

  if (convertToString/* || h > 0*/) {
    const components = [];
    if (h > 0) components.push(h + ` ${t('hr')}`);
    if (m > 0) components.push(m + ` ${t('min')}`);
    if (s > 0) components.push(s + ` ${t('sec')}`);
    result = components.join(' ');
  }

  return <span className={className}>{result}</span>;
};
