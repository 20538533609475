import { isPlatform } from '@ionic/react';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
const isDesktop = isPlatform('desktop');

export const PORTRAIT_PRIMARY = 'portrait-primary';
export const LANDSCAPE_PRIMARY = 'landscape-primary';

export const getSetScreenOrientation = () => {
  return ScreenOrientation.type;
}

export const setScreenOrientationPortrait = () => {
  if (!isDesktop) {
    ScreenOrientation.unlock();
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY).then((a) => {
    }, () => {
      setScreenOrientationPortrait();
    });
  }
};

export const setScreenOrientationLandscape = () => {
  if (!isDesktop) {
    ScreenOrientation.unlock();
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY).then((a) => {
      console.log(a);
    }, () => {
      setScreenOrientationLandscape();
    });
  }
};

export const setScreenOrientationChangeHandler = () => {
  ScreenOrientation.onChange().subscribe(
    () => {
        console.log("11 Orientation Changed");
    }
 );
}