import styles from './Intro.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntroSlider, IntroSlide } from 'components/IntroSlider';
import * as Text from 'components/Text';
import { TEXT_POSITION } from 'components/IntroSlider/IntroSlide/types';
import intro2 from 'assets/intro-2.png';
import intro1 from 'assets/intro-1.png';
import intro3 from 'assets/intro-3.png';
import trainer from 'assets/trainer.png';

export const Intro: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IntroSlider>
      <IntroSlide imageSrc={intro1}>
        <h1>
          <Text.LargeAndBold>{t('Train anytime, anywhere')}</Text.LargeAndBold>
        </h1>
        <p className={styles.description}>
          <Text.Body color="medium">
            {t('Daily workout routines in the palm of your hand')}
          </Text.Body>
        </p>
      </IntroSlide>
      <IntroSlide imageSrc={intro2}>
        <h1>
          <Text.LargeAndBold>
            {t('Eat healthy, stay energized')}
          </Text.LargeAndBold>
        </h1>
        <p className={styles.description}>
          <Text.Body color="medium">
            {t('Quick and easy recipes meant to keep you fit')}
          </Text.Body>
        </p>
      </IntroSlide>
      <IntroSlide imageSrc={intro3}>
        <h1>
          <Text.LargeAndBold>{t('Tips & tricks')}</Text.LargeAndBold>
        </h1>
        <p className={styles.description}>
          <Text.Body color="medium">
            {t('Get ahead of the game, see quicker results')}
          </Text.Body>
        </p>
      </IntroSlide>
      <IntroSlide imageSrc={trainer} textPosition={TEXT_POSITION.BOTTOM}>
        <h1>
          <Text.LargeAndBold>
            {t('Welcome to a healthy new you!')}
          </Text.LargeAndBold>
        </h1>
        <p className={styles.description}>
          <Text.Body color="medium">
            {t(
              'Workout from home, or even from the office, get tips & tricks to help you reach your goals quicker. No more excuses!'
            )}
          </Text.Body>
        </p>
      </IntroSlide>
    </IntroSlider>
  );
};
