import styles from './Error.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { Icon } from 'components/Icon';
import * as Text from 'components/Text';

export const Error: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const retry = () => {
    window.location.reload();
  }

  return (
    <div className={classNames(styles.container, 'centered')}>
      <Icon color={'dark'} size={'large'} name={'barbellOutline'} className={styles.errorImage}/>
      <Text.ExtraLarge color={'dark'} className={'size-5-padding-top'}>
        {t('Ouch!')}
      </Text.ExtraLarge>
      <Text.Bigger color={'dark'} className={'size-5-padding-bottom'}>
        {t('Too much weight...')}
      </Text.Bigger>
      <IonButton onClick={retry}>
        {t('Try again')}
      </IonButton>
    </div>
  );
});
