import styles from './AspectRatio.module.scss';
import React from 'react';
import classNames from 'classnames';
import { AspectRatioProps } from './types';

export const AspectRatio: React.FC<AspectRatioProps> = ({
  ratio,
  expandOnFullScreen = true,
  className,
  children,
}) => {
  let percentage = 100;
  if (!!ratio) {
    const [widthString, heightString] = ratio.split(':');
    const width = parseInt(widthString, 10);
    const height = parseInt(heightString, 10);
    if (typeof width === 'number' && typeof height === 'number') {
      percentage = (height / width) * 100;
    }
  }
  return (
    <div
      style={{ paddingTop: `${percentage}%` }}
      className={classNames(
        styles.container,
        {
          [styles.expandOnFullScreen]: expandOnFullScreen,
        },
        className
        )}
    >
      {children}
    </div>
  );
};
