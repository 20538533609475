import styles from './Tags.module.scss';
import React from 'react';
import classNames from 'classnames';
import { TagsProps } from './types';

export const Tags: React.FC<TagsProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {children}
    </div>
  );
};
