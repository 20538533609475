import styles from './ItemPageContent.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonFab,
  IonFabButton,
  isPlatform,
  useIonModal,
} from '@ionic/react';
import { Author } from 'components/Author';
import { ItemPageContentProps } from './types';
import { Media } from 'components/Media';
import * as Text from 'components/Text';
import { Tag } from 'components/Tag';
import { Tags } from 'components/Tags';
import { EnergyContent } from 'components/EnergyContent';
import { useTranslation } from 'react-i18next';
import { appPages } from 'components/Menu';
import { CheckItem } from 'components/CheckItem';
import { ExerciseSet } from './ExerciseSet';
import { Icon } from 'components/Icon';
import { Congratulations } from '../Congratulations';
import { WorkoutHeader } from './WorkoutHeader';
import { CurrentExerciseProps } from './WorkoutHeader/types';
import { App } from '@capacitor/app';
import {
  PORTRAIT_PRIMARY,
  LANDSCAPE_PRIMARY,
  getSetScreenOrientation,
  setScreenOrientationLandscape,
  setScreenOrientationPortrait,
  setScreenOrientationChangeHandler,
} from 'helpers/screenOrientation';
import storage from 'helpers/storage';
const isDesktop = isPlatform('desktop');

let isMounted = false;
let exerciseIndices: number[] = [];
const NO_EXERCISE = 0;

export const ItemPageContent: React.FC<ItemPageContentProps> = React.memo((
  {
    pageLoaded,
    itemType,
    id,
    title,
    description,
    thumbnail,
    media,
    level,
    minutes,
    type,
    cal,
    protein,
    fat,
    saturatedFat,
    carbs,
    sugar,
    fiber,
    ingredients,
    steps,
    sets,
  },
) => {
  const [isAppInForeground, setIsAppInForeground] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showCongratulations, hideCongratulations] = useIonModal(Congratulations);

  useEffect(() => {
    isMounted = true;
    App.addListener('appStateChange', ({ isActive }) => {
      if (isMounted) setIsAppInForeground(isActive);
    });
    storage.getItem('isMuted').then(persistedIsMuted => setIsMuted(persistedIsMuted));

    return () => {
      isMounted = false;
      hideCongratulations();
      document.body.classList.remove('fullscreen');
      setScreenOrientationPortrait();
    };
  }, [hideCongratulations]);

  const toggleIsMuted = () => {
    if (!isMounted) return;
    setIsMuted(!isMuted);
    storage.setItem('isMuted', !isMuted).then();
  };
  const toggleIsPaused = () => {
    if (!isMounted) return;
    setIsPaused(!isPaused);
  };

  const { t } = useTranslation();
  let pageTitle = appPages.find(item => item.url === `/${itemType}s`)?.title || '';
  if (!!pageTitle) {
    const pageTitleChars = pageTitle.split('');
    pageTitleChars.pop();
    pageTitle = pageTitleChars.join('');
  }

  const [currentExercise, setCurrentExercise] = useState(null as unknown as CurrentExerciseProps);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(-1);
  const [currentExerciseId, setCurrentExerciseId] = useState(NO_EXERCISE);
  const [isWorkoutFinished, setIsWorkoutFinished] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [screenOrientation, setScreenOrientation] = useState(PORTRAIT_PRIMARY);
  setScreenOrientationChangeHandler();
  // setScreenOrientationChangeHandler(() => () => {
  //   console.log(1);
  //   const currentScreenOrientation = getSetScreenOrientation();
  //   setScreenOrientation(currentScreenOrientation);
  //   setFullScreen(currentScreenOrientation === LANDSCAPE_PRIMARY);
  //   if (!!currentExerciseId && !!exerciseRefs?.current?.[currentExerciseId?.toString()]?.scrollIntoView) {
  //     exerciseRefs.current[currentExerciseId.toString()].scrollIntoView({ behavior: 'smooth' });
  //   }
  // });
  const toggleFullScreen = () => {
    if (!fullScreen) {
      setScreenOrientationLandscape();
      document.body.classList.add('fullscreen');
      console.log(fullScreen);
    } else {
      setScreenOrientationPortrait();
      document.body.classList.remove('fullscreen');
      if (isDesktop && !!currentExerciseId && !!exerciseRefs?.current?.[currentExerciseId?.toString()]?.scrollIntoView) {
        exerciseRefs.current[currentExerciseId.toString()].scrollIntoView({ behavior: 'smooth' });
      }
    }
    setFullScreen(!fullScreen);
  };
  const exerciseRefs: { current: { [s: string]: Element; } } = useRef({});

  const getExerciseInfo = (exerciseId: number): CurrentExerciseProps => {
    if (!exerciseId) return null as unknown as CurrentExerciseProps;
    const exerciseIdChars = exerciseId.toString(10).split('');
    const exerciseRoundIndex = parseInt((exerciseIdChars.splice(-2).join('')), 10) - 1;
    const exerciseIndex = parseInt((exerciseIdChars.splice(-2).join('')), 10) - 1;
    const setRoundIndex = parseInt((exerciseIdChars.splice(-2).join('')), 10) - 1;
    const setIndex = parseInt((exerciseIdChars.join('')), 10) - 1;

    const set = sets?.[setIndex];
    const exercise = sets?.[setIndex]?.exercises?.[exerciseIndex];
    const setTitle = set?.title;
    const setRounds = set?.reps || 1;
    const exerciseTitle = exercise?.exercise?.title;
    const exerciseRounds = exercise?.sets || 1;
    const exerciseMinutes = (exercise?.minutes || 0) + ((exercise?.seconds || 0) / 60);
    const exerciseThumbnail = exercise?.exercise?.thumbnail;
    const exerciseMedia = exercise?.exercise?.media;

    return {
      setTitle,
      setRounds,
      setRound: setRoundIndex + 1,
      exerciseTitle,
      exerciseRounds,
      exerciseRound: exerciseRoundIndex + 1,
      exerciseMinutes,
      exerciseThumbnail,
      exerciseMedia,
    };
  };

  const selectExercise = (exerciseId: number) => {
    if (!isMounted) return;
    if (isPaused) {
      setIsPaused(false);
    }
    if (!exerciseIndices || !exerciseIndices.length) {
      exerciseIndices = [...Object.keys(exerciseRefs.current).map(key => parseInt(key, 10))].sort();
    }
    if (isWorkoutFinished) setIsWorkoutFinished(false);
    setCurrentExerciseId(exerciseId);
    setCurrentExerciseIndex(exerciseIndices.indexOf(exerciseId));
    if (!!exerciseId && !fullScreen && !!exerciseRefs?.current?.[exerciseId?.toString()]?.scrollIntoView) {
      exerciseRefs.current[exerciseId.toString()].scrollIntoView({ behavior: 'smooth' });
    }
    setCurrentExercise(getExerciseInfo(exerciseId));
  };

  const nextExercise = () => {
    if (!isMounted) return;
    if (isPaused) {
      setIsPaused(false);
      return;
    }
    if (!exerciseIndices || !exerciseIndices.length) {
      exerciseIndices = [...Object.keys(exerciseRefs.current).map(key => parseInt(key, 10))].sort();
    }
    if (currentExerciseIndex < exerciseIndices.length - 1) {
      const newIndex = currentExerciseIndex + 1;
      const newExerciseId = exerciseIndices[newIndex];
      selectExercise(newExerciseId);
    } else {
      selectExercise(NO_EXERCISE);
      showCongratulations();
      setIsWorkoutFinished(true);
      setTimeout(() => {
        if (isMounted) hideCongratulations();
      }, 1000);
    }
  };

  const itemHeaderComponent = (
    <IonHeader className={'ion-no-border'}>
      <IonToolbar>
        <IonButtons slot={'start'}>
          <IonBackButton defaultHref={`/${itemType}s`} />
        </IonButtons>
        <IonTitle>{t(pageTitle)}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );

  const workoutHeaderComponent = (
    <WorkoutHeader
      id={id}
      currentExerciseId={currentExerciseId}
      workoutTitle={pageTitle}
      currentExercise={currentExercise || {}}
      nextExercise={nextExercise}
      fullScreen={fullScreen}
      toggleFullScreen={toggleFullScreen}
      screenOrientation={screenOrientation}
      isPaused={!isAppInForeground || isPaused}
      toggleIsPaused={toggleIsPaused}
      isMuted={isMuted}
      toggleIsMuted={toggleIsMuted}
    >
    </WorkoutHeader>
  );

  const mediaComponent = !!currentExercise?.exerciseMedia?.[0]?.url || !!currentExercise?.exerciseThumbnail?.url ? (
    <div className={classNames(styles.mediaWrapper, { [styles.fullScreen]: fullScreen })}>
      <Media media={currentExercise.exerciseMedia} thumbnail={currentExercise.exerciseThumbnail} />
    </div>
  ) : (
    <Media media={media} thumbnail={thumbnail} />
  );

  const topSectionComponent = (
    <section>
      {mediaComponent}
      {!!title && (
        <h1 className={'size-5-padding-horizontal size-5-margin-bottom'}>
          <Text.LargerAndBold>{title}</Text.LargerAndBold></h1>
      )}

      {(!!level || !!minutes) && (
        <Tags className={'size-5-padding-horizontal size-5-padding-bottom'}>
          {/*<Tag type={'weight'} value={'3.5'} className={'size-2-margin-start size-3-margin-top'}/>*/}
          {!!level && <Tag type={'level'} value={level} className={'size-2-margin-start size-3-margin-top'} />}
          {!!minutes && itemType === 'workout' &&
          <Tag type={'workoutTime'} value={minutes} className={'size-2-margin-start size-3-margin-top'} />}
          {!!type && <Tag type={'mealType'} value={type} className={'size-2-margin-start size-3-margin-top'} />}
          {!!minutes && itemType === 'meal' &&
          <Tag type={'mealTime'} value={minutes} className={'size-2-margin-start size-3-margin-top'} />}
          {!!cal && <Tag type={'kCalories'} value={cal} className={'size-2-margin-start size-3-margin-top'} />}
        </Tags>
      )}

      <EnergyContent
        proteinPerServing={protein}
        fatPerServing={fat}
        saturatedFatPerServing={saturatedFat}
        carbohydratesPerServing={carbs}
        sugarPerServing={sugar}
        fiberPerServing={fiber}
        // proteinPer100g={12.5}
        // fatPer100g={5}
        // saturatedFatPer100g={1}
        // carbohydratesPer100g={36.5}
        // sugarPer100g={11.5}
        // fiberPer100g={7}
        className={'size-5-padding-horizontal size-4-margin-bottom'}
      />

      {!!description && (
        <div className={'size-5-padding-horizontal'}>
          <Text.Body>{description}</Text.Body>
        </div>
      )}
    </section>
  );

  const authorComponent = (
    <Author
      name={'Cornel Duta'}
      title={'Personal Trainer'}
      photo={'https://cornelius-data-dev.s3.eu-central-1.amazonaws.com/dc238244180448e8b1949a4684f02ed6.jpg'}
      size={'large'}
      className={'size-5-padding-horizontal'}
    />
  );

  const pageContentComponent = (
    <>
      <section className={'size-5-padding-horizontal'}>
        {!!ingredients && !!ingredients.length && (
          <div className={'size-5-margin-bottom'}>
            <h2 className={'size-3-padding-bottom'}><Text.LargeAndBold>{t('Ingredients')}</Text.LargeAndBold></h2>
            <IonList>
              {ingredients.map((item, index) => (
                <CheckItem key={index}>
                  <strong>{item.quantity || 1}</strong> {item.name}
                </CheckItem>
              ))}
            </IonList>
          </div>
        )}
        {!!steps && !!steps.length && (
          <div>
            <h2 className={'size-3-padding-bottom'}><Text.LargeAndBold>{t('Steps')}</Text.LargeAndBold></h2>
            <IonList lines={'none'}>
              {steps.map((item, index) => (
                <IonItem key={index} className={'ion-align-items-start size-3-padding-bottom'}>
                  <strong slot={'start'} className={'size-2-padding-horizontal'}>{index + 1}{'. '}</strong>
                  <Text.Body>{item.step}</Text.Body>
                </IonItem>
              ))}
            </IonList>
          </div>
        )}
      </section>
      <div className={'size-5-padding-horizontal'}>
        {!!sets && !!sets.length && (
          <div>
            {sets.map((set, index) => (
              <ExerciseSet
                setIndex={index}
                exerciseRefs={exerciseRefs}
                currentExerciseId={currentExerciseId}
                selectExercise={selectExercise}
                {...set}
                key={index}
              />
            ))}
          </div>
        )}
        {authorComponent}
      </div>
    </>
  );

  let fabIcon = 'playSkipForward';
  if ((currentExerciseIndex === -1 && !isWorkoutFinished) || isPaused) fabIcon = 'play';
  if (isWorkoutFinished) fabIcon = 'reload';
  const fabComponent = itemType === 'workout' && (
    <IonFab vertical={'bottom'} horizontal={'end'} slot={'fixed'}>
      {pageLoaded && (
        <IonFabButton color={'secondary'} onClick={nextExercise}>
          <Icon name={fabIcon} />
        </IonFabButton>
      )}
    </IonFab>
  );

  return (
    <>
      {itemType === 'workout' ? workoutHeaderComponent : itemHeaderComponent}

      <IonContent fullscreen className={classNames({ [styles.workout]: itemType === 'workout' })}>
        <div className={classNames(styles.container, { [styles.fullScreen]: fullScreen })}>
          <div className={styles.topSection}>
            {topSectionComponent}
          </div>

          <div className={styles.bottomSection}>
            {pageContentComponent}
          </div>
        </div>
        {fabComponent}
      </IonContent>
    </>
  );
});
