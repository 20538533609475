import styles from './ImageBackground.module.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import { IonImg } from '@ionic/react';
import { ImageBackgroundProps } from './types';

export const ImageBackground: React.FC<ImageBackgroundProps> = (
  {
    src,
    objectFit = 'cover',
    objectPosition = 'center center',
    className,
  },
) => {
  const [loaded, setLoaded] = useState(false);
  const didLoad = () => setLoaded(true);

  return (
    <IonImg
      onIonImgDidLoad={didLoad}
      src={src}
      className={classNames(
        styles.container,
        {
          [styles.fitContain]: objectFit === 'contain',
          [styles.positionBottom]: objectPosition === 'bottom',
          [styles.loaded]: loaded,
        },
        className
      )}
      style={{objectPosition}}
    />
  );
};
