import styles from './SignInPage.module.scss';
import React, {useEffect} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IonHeader, IonFooter, IonContent, IonPage, IonButton } from "@ionic/react";
import { Intro } from './Intro';
import logoLight from "../../assets/logo-light.png";
import logoDark from "../../assets/logo-dark.png";

const SignInPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('fullscreen');
    return () => {
      document.body.classList.remove('fullscreen');
    }
  }, []);

  const { t } = useTranslation();

  return (
    <IonPage className={'safe-padding'}>
      <IonHeader className={'ion-text-center ion-no-border'}>
        <img src={logoDark} alt="" height="120" className={classNames(styles.logo, styles.lightTheme, 'size-5-padding-top')} />
        <img src={logoLight} alt="" height="120" className={classNames(styles.logo, styles.darkTheme, 'size-5-padding-top')} />
      </IonHeader>
      <IonContent scrollY={false}>
        <Intro />
      </IonContent>
      <IonFooter className={'ion-no-border ion-text-center size-5-padding'}>
        <IonButton className={styles.ctaButton} routerLink={'/trainer'} routerDirection={'root'} expand={'block'} shape={'round'}>{t('Meet your trainer').toUpperCase()}</IonButton>
      </IonFooter>
    </IonPage>
  );
};
export default SignInPage;
