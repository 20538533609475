import { Storage } from '@capacitor/storage';

const setItem = async (key: string, value: any) => {
  return Storage.set({
    key,
    value: JSON.stringify(value),
  });
};

const getItem = (key: string) => {
  return Storage.get({ key }).then(({value}) => {
    if (value === null) return null;
    return JSON.parse(value);
  });
};

const removeItem = async (key: string) => {
  return Storage.remove({ key });
};

const clear = () => {
  return Storage.clear();
};

const storage = {
  setItem,
  getItem,
  removeItem,
  clear
};

export default storage;