import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from './data';
import { setScreenOrientationPortrait } from "./helpers/screenOrientation";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import storage from "helpers/storage";
import { polyfill as smoothScrollPolyfill } from 'helpers/smoothScrollPolyfill';
import { ErrorBoundary } from './components/ErrorBoundary';
// import { ReactQueryDevtools } from 'react-query-devtools';

smoothScrollPolyfill();

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE || '');

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
toggleDarkTheme(prefersDark.matches);
// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd: boolean) {
  document.body.classList.toggle('dark', shouldAdd);
}

setScreenOrientationPortrait();

const renderApp = async () => {
  const firstTimeUser = !(await storage.getItem('user'));
  storage.setItem('user', true).then();
  render(
    <ErrorBoundary>
      <Provider>
        <IonApp>
          {/*<ReactQueryDevtools/>*/}
          <IonReactRouter>
            <App firstTimeUser={firstTimeUser}/>
          </IonReactRouter>
        </IonApp>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
