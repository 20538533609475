import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardItem } from "components/CardItem";
import { CardItemList } from "components/CardItemList";
import { DataLoader } from "data/graphql/DataLoader";
import { getListQuery } from "data/graphql/queries";
import { appPages } from "components/Menu";

const ItemsPage: React.FC<{ listType: string, itemType: string }> = React.memo((
  {
    listType,
    itemType
  }
) => {
  const { t } = useTranslation();
  let dataKey = listType;
  if (listType === "weekly-workouts") dataKey = "workouts";
  if (listType === "exercises") dataKey = "workouts";
  let query = getListQuery(listType)();
  const pageTitle = appPages.find(item => item.url === `/${listType}`)?.title || '';

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color={'dark'}/>
          </IonButtons>
          <IonTitle>{t(pageTitle)}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <DataLoader queryKey={listType} query={query}>
          {(props: any) => !props.data || !props.data[dataKey] ? null : (
            <CardItemList>
              {props.data[dataKey].map((item: any, index: number) => (
                <CardItem itemType={itemType} {...item} key={index}/>
              ))}
            </CardItemList>
          )}
        </DataLoader>
      </IonContent>
    </IonPage>
  );
});
export default ItemsPage;
