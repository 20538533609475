import React from 'react';
import classNames from 'classnames';
import { IonText } from '@ionic/react';
import { TextProps } from './types';

export const Huge: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('huge', className)}>
      {children}
    </IonText>
  );
};

export const XXL: React.FC<TextProps> = ({
                                           children,
                                           className,
                                           ...props
                                         }) => {
  return (
    <IonText {...props} className={classNames('extraExtraLarge', className)}>
      {children}
    </IonText>
  );
};

export const ExtraLarge: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('extraLarge', className)}>
      {children}
    </IonText>
  );
};

export const LargerAndBold: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('largerAndBold', className)}>
      {children}
    </IonText>
  );
};

export const Larger: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('larger', className)}>
      {children}
    </IonText>
  );
};

export const LargeAndBold: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('largeAndBold', className)}>
      {children}
    </IonText>
  );
};

export const Large: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('large', className)}>
      {children}
    </IonText>
  );
};

export const BiggerAndBold: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('biggerAndBold', className)}>
      {children}
    </IonText>
  );
};

export const Bigger: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('bigger', className)}>
      {children}
    </IonText>
  );
};

export const Body: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('body', className)}>
      {children}
    </IonText>
  );
};

export const Smaller: React.FC<TextProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <IonText {...props} className={classNames('smaller', className)}>
      {children}
    </IonText>
  );
};
