import styles from './Tag.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TagProps } from './types';
import { Icon } from 'components/Icon';
import { NumberFormat } from 'components/NumberFormat';
import { TimeFormat } from 'components/TimeFormat';
import { WeightFormat } from "components/WeightFormat";

export const Tag: React.FC<TagProps> = ({ type, value, className }) => {
  const { t } = useTranslation();

  if (!type || !value) return null;

  const LEVELS: { [key: string]: string } = {
    all: t('all levels'),
    beginner: t('beginner'),
    intermediate: t('intermediate'),
    advanced: t('advanced'),
  };

  const MEAL_TYPES: { [key: string]: string } = {
    regular: t('regular'),
    pescatarian: t('pescatarian'),
    vegetarian: t('vegetarian'),
    vegan: t('vegan'),
  };

  switch (type) {
    case 'weight':
      return (
        <div className={classNames(styles.container, className)}>
          <Icon name="barbellOutline" className="size-1-padding-end" />
          <WeightFormat kg={value}/>
        </div>
      );
    case 'level':
      return (
        <div className={classNames(styles.container, className)}>
          <Icon name="podiumOutline" className="size-1-padding-end" />
          {LEVELS[value] || value}
        </div>
      );
    case 'workoutTime':
      return (
        <div className={classNames(styles.container, className)}>
          <Icon name="stopwatchOutline" className="size-1-padding-end" />
          <TimeFormat minutes={value} />
        </div>
      );
    case 'mealTime':
      return (
        <div className={classNames(styles.container, className)}>
          <Icon name="alarmOutline" className="size-1-padding-end" />
          <TimeFormat minutes={value} convertToString />
        </div>
      );
    case 'kCalories':
      return (
        <div className={classNames(styles.container, className)}>
          <Icon name="flameOutline" className="size-1-padding-end" />
          <NumberFormat
            value={value}
            maximumFractionDigits={0}
            className="size-1-padding-end"
          />
          {t('kcal')}
        </div>
      );
    case 'mealType':
      return (
        <div className={classNames(styles.container, className)}>
          {MEAL_TYPES[value] || value}
        </div>
      );
    default:
      return null;
  }
};
