import styles from './CardItemList.module.scss';
import React from 'react';
import classNames from 'classnames';
import { CardItemListProps } from './types';

export const CardItemList: React.FC<CardItemListProps> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
};
