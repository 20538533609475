import React, {useState, useEffect, useRef} from "react";
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { Loading } from "components/Loading";
import { Error } from "components/Error";

export const DataLoader: React.FC<{
  queryKey: string,
  query: string,
  variables?: { [s: string]: any; },
  refetchOnWindowFocus?: boolean
}> = React.memo((
  {
    queryKey,
    query,
    variables = {},
    refetchOnWindowFocus = false,
    children
  }
) => {
  const isMounted = useRef(false);
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])
  const endDelay = () => {
    if (isMounted.current) {
      setDelayed(false);
    }
  }

  const { status, data } = useQuery(
    [queryKey, ...Object.values(variables)],
    async () => {
      const requestHeaders: { [s: string]: any; } = {};
      if (!!process.env.REACT_APP_API_TOKEN) {
        requestHeaders['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
      }
      setTimeout(endDelay, 1000)
      return await request(
        process.env.REACT_APP_API_URL || '',
        query,
        variables,
        requestHeaders
      );
    },
    {
      enabled: !Object.values(variables).some(variable => !variable),
      retry: 5,
      refetchOnWindowFocus,
    }
  );

  if (typeof children === "function") {
    if (!!data && status !== "loading" && !delayed) console.log(data);
    return (
      <>
        {!!data && children({ data, pageLoaded: !delayed })}
        {(status === "loading" || delayed) && <Loading/>}
        {(status === "error" && !delayed) && <Error/>}
      </>
    );
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { data, pageLoaded: !delayed });
    }
    return child;
  });

  return (
    <>
      {!!data && childrenWithProps}
      {(status === "loading" || delayed) && <Loading/>}
      {(status === "error" && !delayed) && <Error/>}
    </>
  );
});
