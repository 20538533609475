/**
 * Function that shuffles an array in place
 * @param array
 */
export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

/**
 * Function that a promise with a custom delay
 * @param ms
 */
export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 * Function that preloads an image
 * @param url
 * @param onComplete
 */
export const preloadImage = (url: string, onComplete: any) => {
  const img=new Image();
  img.src=url;
  img.onload=onComplete;
  img.onerror=onComplete;
  img.alt = '';
};

/**
 * Returns a function that will only be executed on and after the Nth call.
 * @param times
 * @param func
 */
export const after = (times: number, func: Function) => {
  return function() {
    if (--times < 1) {
      return func.apply(null, arguments);
    }
  };
}