import React from 'react';
import mime from 'mime-types';
import { MediaPlayerProps } from './types';
import { ImageBackground } from 'components/ImageBackground';
import { VideoBackground } from "components/VideoBackground";
import { YouTubeBackground } from "components/YouTubeBackground";

export const MediaPlayer: React.FC<MediaPlayerProps> = React.memo((
  {
    url,
  }
) => {
  if (!url) return null;
  const extension = url.split('.').pop() || 'jpeg';
  let fileType = (mime.lookup(extension) || 'image/jpeg');
  if (fileType === 'video/quicktime') fileType = 'video/mp4';

  switch (fileType.split('/')[0]) {
    case 'image':
      return <ImageBackground key={url} src={url}/>;
    case 'video':
      return <VideoBackground key={url} src={url}/>;
    case 'youtube':
      return  <YouTubeBackground key={url} src={url}/>
    default:
      return <ImageBackground key={url} src={url}/>;
  }
});
