import styles from './IntroSlider.module.scss';
import React from 'react';
import { IonicSwiper } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { IntroSliderProps } from './types';

export { IntroSlide } from './IntroSlide';

SwiperCore.use([IonicSwiper, Pagination]);

export const IntroSlider: React.FC<IntroSliderProps> = ({ children }) => {
  const wrappedChildren = React.Children.map(children, child => {
    return (
      <SwiperSlide>
        {child}
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      speed={400}
      pagination
      className={styles.introSlides}
    >
      {wrappedChildren}
    </Swiper>
  );
};
