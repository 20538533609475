import styles from './SpinningDigit.module.scss';
import React from 'react';
import classNames from 'classnames';
import { SpinningDigitProps } from './types';

export const SpinningDigit: React.FC<SpinningDigitProps> = ({
  value,
  className,
}) => {
  if (typeof value === 'undefined') return null;
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;

  return (
    <div className={classNames(styles.container, className)}>
      <div
        className={classNames(styles.allDigits, styles.animate)}
        style={{ top: `-${parsedValue}em` }}
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit, index) => (
          <span className={styles.digit} key={`SpinningDigit_${index}`}>
            {digit}
          </span>
        ))}
      </div>
    </div>
  );
};
