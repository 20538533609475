import React from 'react';
import { useField } from 'formik';
import { SelectProps } from './types';
import { IonItem, IonLabel, IonSelect, IonText } from '@ionic/react';

export const Select: React.FC<SelectProps> = (props) => {
  const [field, meta, helper] = useField<{}>(props);
  const changeHandler = (e: any) => {
    helper.setValue(e.detail.value);
    if (!meta.touched) helper.setTouched(true);
  };
  return (
    <IonItem>
      <IonLabel>{props.label}</IonLabel>

      <IonSelect value={field.value} onIonChange={changeHandler}>
        {props.children}
      </IonSelect>
      {!!meta.error && meta.touched && (
        <IonText color="danger">{meta.error}</IonText>
      )}
    </IonItem>
  );
};
