import React from "react";
import { useTranslation } from "react-i18next";
import { ExerciseSetProps } from "./types";
import * as Text from "components/Text";
import { Exercise } from "../Exercise";
import { Icon } from "components/Icon";

export const ExerciseSet: React.FC<ExerciseSetProps> = React.memo((
  {
    setIndex,
    title,
    // subtitle,
    // description,
    reps,
    exercises = [],
    exerciseRefs,
    currentExerciseId,
    selectExercise
  }
) => {
  const { t } = useTranslation();
  const rounds = Array.from({ length: reps || 1 }, (_, i) => i + 1);

  return (
    <>
      {!!title && <h3 className={"size-2-margin-vertical"}><Text.LargeAndBold>{title}</Text.LargeAndBold></h3>}
      {/*{!!subtitle && <Text.Larger>{subtitle}</Text.Larger>}*/}
      {/*{!!description && <Text.Large>{description}</Text.Large>}*/}
      {rounds.map((round, setRoundIndex) => (
        <div className="exercise-set" key={setRoundIndex}>
          {rounds.length > 1 && (
            <h5 className={"d-flex ion-align-items-center size-2-margin-vertical"}>
              <Icon name="repeatOutline" className="size-1-padding-end"/>
              <Text.Body>
                {t("Round") + " " + round}
              </Text.Body>
            </h5>
          )}
          {exercises.map((exercise, exerciseIndex) => {
            const exerciseSets = Array.from({ length: exercise.sets || 1 }, (_, i) => i + 1);

            return (
              <React.Fragment key={exerciseIndex}>
                {exerciseSets.map((exerciseSet, exerciseRoundIndex) => (
                  <Exercise
                    setIndex={setIndex}
                    setRoundIndex={setRoundIndex}
                    exerciseIndex={exerciseIndex}
                    exerciseRoundIndex={exerciseRoundIndex}
                    exerciseSet={exerciseSet}
                    {...exercise}
                    exerciseRefs={exerciseRefs}
                    currentExerciseId={currentExerciseId}
                    selectExercise={selectExercise}
                    key={exerciseRoundIndex}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
      ))}
    </>
  );
});
