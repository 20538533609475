import styles from './Footer.module.scss';
import classNames from 'classnames';
import React from 'react';
import { IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'components/Divider';
// import logoDark from 'assets/large-logo-dark.png';

const Footer: React.FC<{ isContained?: boolean; }> = ({ isContained = false }) => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className={styles.navWrapper}>
        <div className={classNames({ [styles.container]: isContained }, 'size-8-padding-vertical size-5-padding-start')}>
          <div className={styles.logoAndNav}>
            <div className={'centered'}>
              {/*<img src={logoDark} alt='' height='120' className={classNames(styles.logo)} />*/}
            </div>
            <nav className={styles.nav}>
              <IonRouterLink color={'light'} routerLink={'/terms'} routerDirection={'root'} className={classNames(styles.navLink)}>
                <strong>{t('TERMS AND CONDITIONS')}</strong>
              </IonRouterLink>
              <IonRouterLink color={'light'} routerLink={'/privacy'} routerDirection={'root'} className={classNames(styles.navLink)}>
                <strong>{t('PRIVACY POLICY')}</strong>
              </IonRouterLink>
              <IonRouterLink color={'light'} routerLink={'/cookie-policy'} routerDirection={'root'} className={classNames(styles.navLink)}>
                <strong>{t('COOKIE POLICY')}</strong>
              </IonRouterLink>
            </nav>
          </div>
        </div>
      </div>
      <Divider className={styles.divider}/>
      <div className={classNames(styles.copyright, 'size-7-padding-vertical size-5-padding-horizontal')}>
        <div className={classNames({ [styles.container]: isContained }, 'd-flex ion-justify-content-between')}>
          <div>
            {/* SOCIAL MEDIA LINKS */}
          </div>
          <div>
            &copy; {(new Date()).getFullYear()} CORNELIUS.APP
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;