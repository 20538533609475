import styles from './Loading.module.scss';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IonAvatar } from '@ionic/react';
import { IMAGE_PLACEHOLDER, LOADING_IMAGES } from 'theme/config';
import { shuffleArray } from 'helpers/functions';
import * as Text from "../Text";

let shuffledLoadingImages = [...LOADING_IMAGES];
export const Loading: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [currentIndex, setIndex] = useState(0);
  useEffect(() => {
    shuffleArray(shuffledLoadingImages);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((currentIndex + 1) % (shuffledLoadingImages.length || 1));
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <div className={classNames(styles.container, 'centered')}>
      <IonAvatar className={styles.loadingImage}>
        <img
          alt=""
          src={shuffledLoadingImages[currentIndex] || IMAGE_PLACEHOLDER}
        />
      </IonAvatar>
      <Text.Bigger color={'dark'} className="size-4-padding-top">
        {t("Just a sec, I'm stretching!")}
      </Text.Bigger>
    </div>
  );
});
