import { gql } from "graphql-request";

const PAGE_SIZE = 100;

export const getListQuery = (queryKey: string) => {
  switch (queryKey) {
    case 'workouts':
      return workoutsList;
    case 'weekly-workouts':
      return weeklyWorkoutsList;
    case 'exercises':
      return exercisesList;
    case 'meals':
      return mealsList;
    case 'tips':
      return tipsList;
    default:
      return workoutsList;
  }
}

export const getItemQuery = (queryKey: string) => {
  switch (queryKey) {
    case 'workout':
      return workoutItem;
    case 'meal':
      return mealItem;
    case 'tip':
      return tipItem;
    default:
      return workoutItem;
  }
}

export const workoutsList = (page = 0, filter = '') => gql`
query {
  workouts(where: {${filter} category: "general" visible: true} limit:${PAGE_SIZE} start: ${page} sort: "updated_at:desc") {
    id,
    title,
    category,
    level,
    minutes,
    public,
    thumbnail {
      url
    },
    media {
      url
    }
  }
}
`;

export const weeklyWorkoutsList = () => gql`
query {
  workouts(where: {category: "weekly" visible: true} limit:7 start: 0 sort: "title") {
    id,
    title,
    category,
    level,
    minutes,
    public,
    thumbnail {
      url
    },
    media {
      url
    }
  }
}
`;

export const exercisesList = (page = 0, filter = '') => gql`
query {
  workouts(where: {${filter} category: "exercises" visible: true} limit:${PAGE_SIZE} start: ${page} sort: "updated_at:desc") {
    id,
    title,
    category,
    level,
    minutes,
    public,
    thumbnail {
      url
    },
    media {
      url
    }
  }
}
`;

export const mealsList = (page = 0, filter = '') => gql`
query {
  meals(where: {${filter} visible: true} limit:${PAGE_SIZE} start: ${page} sort: "updated_at:desc") {
    id,
    title,
    minutes,
    type,
    cal,
    public,
    thumbnail {
      url
    }
  }
}
`;

export const tipsList = (page = 0, filter = '') => gql`
query {
  tips(where: {${filter} visible: true} limit:${PAGE_SIZE} start: ${page} sort: "updated_at:desc") {
    id,
    title,
    public,
    thumbnail {
      url
    }
  }
}
`;

export const workoutItem = (id: string) => gql`
query {
  workout(id: ${id}) {
    id,
    title,
    category,
    level,
    minutes,
    public,
    thumbnail {
      url
    },
    media {
      url
    },
    schedule,
    sets {
      title,
      subtitle,
      reps,
      description,
      exercises {
        sets,
        reps,
        kg,
        minutes,
        seconds,
        exercise {
          title,
          description,
          thumbnail {
            url
          },
          media {
            url
          },
        }
      }
    }
  }
}
`;

export const mealItem = (id: string) => gql`
query {
  meal(id: ${id}) {
    id,
    title,
    description,
    minutes,
    type,
    public,
    thumbnail {
      url
    },
    kj,
    cal,
    protein,
    fat,
    saturatedFat,
    carbs,
    sugar,
    fiber,
    ingredients {
      name,
      quantity,
    },
    steps {
      step
    }
  }
}
`;

export const tipItem = (id: string) => gql`
query {
  tip(id: ${id}) {
    id,
    title,
    description,
    public,
    thumbnail {
      url
    },
  }
}
`;

export const trainerQuery = (id: string) => gql`
query {
  trainer(id: ${id}) {
    firstName,
    lastName,
    email,
    phoneNumber,
    about,
    mainPhoto {
      url
    },
    certifications {
      name,
      date,
    },
    specializations {
      name,
    },
  }
}
`;