import styles from './Media.module.scss';
import React from 'react';
import classNames from 'classnames';
import { MediaProps } from './types';
import { AspectRatio } from 'components/AspectRatio';
import { MediaPlayer } from "./MediaPlayer";

const DEFAULT_ASPECT_RATIO = '8:5';

export const Media: React.FC<MediaProps> = React.memo((
  {
    mode = 'media',
    thumbnail,
    media,
    aspectRatio = DEFAULT_ASPECT_RATIO,
    className,
  }
) => {
  let url = thumbnail?.url || media?.[0]?.url; // mode === 'thumbnail'
  if (mode === 'media') {
    url = media?.[0]?.url || thumbnail?.url;
  }
  if (!url) return null;

  return (
    <AspectRatio ratio={aspectRatio} className={classNames(styles.imageWrapper, className)}>
      <MediaPlayer url={url}/>
    </AspectRatio>
  );
});
