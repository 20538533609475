import styles from './WorkoutHeader.module.scss';
import React from 'react';
import { WorkoutHeaderProps } from './types';
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonHeader,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react';
import { Icon } from 'components/Icon';
import { Timer } from './Timer';
import classNames from 'classnames';
import {
  PORTRAIT_PRIMARY,
  LANDSCAPE_PRIMARY,
} from 'helpers/screenOrientation';
const isDesktop = isPlatform('desktop');

export const WorkoutHeader: React.FC<WorkoutHeaderProps> = React.memo((
  {
    id,
    currentExerciseId,
    workoutTitle,
    currentExercise: {
      setTitle,
      setRounds,
      setRound,
      exerciseTitle,
      exerciseRounds,
      exerciseRound,
      exerciseMinutes,
    },
    nextExercise,
    fullScreen,
    toggleFullScreen,
    screenOrientation,
    isPaused,
    toggleIsPaused,
    isMuted,
    toggleIsMuted,
  },
) => {
  let titleRounds = null;
  if (!!exerciseRounds && exerciseRounds > 1) {
    titleRounds = (
      <>
        <Icon name={'repeatOutline'} className={classNames(styles.largeTextIcon, 'size-1-margin-horizontal')} />
        {exerciseRound}/{exerciseRounds}
      </>
    );
  }
  let subtitleRounds = null;
  if (!!setRounds && setRounds > 1) {
    subtitleRounds = (
      <>
        <Icon name={'repeatOutline'} className={classNames(styles.smallTextIcon, 'size-1-margin-horizontal')} />
        {setRound}/{setRounds}
      </>
    );
  }

  return (
    <IonHeader className={'ion-no-border'}>
      <IonToolbar className={classNames({ [styles.translucent]: fullScreen })}>
        <IonButtons slot={'start'}>
          <IonBackButton color={'dark'} defaultHref={'/weekly-workouts'} />
        </IonButtons>
        <IonTitle className={classNames({ [styles.workoutTitle]: !!currentExerciseId && !!exerciseMinutes })}>
          {!!exerciseTitle ? (
            <div className={'text-overflow-ellipsis'}>
              {exerciseTitle} {titleRounds}
            </div>
          ) : workoutTitle}
          {!!setTitle && (
            <div className={'smaller text-overflow-ellipsis'}>
              {setTitle} {subtitleRounds}
            </div>
          )}
        </IonTitle>
        <IonButtons slot={'end'}>
          {!!currentExerciseId && !!exerciseMinutes && (
            <IonButton
              color={'dark'}
              onClick={toggleIsPaused}
              className={styles.headerButton}
            >
              <Timer
                key={`${id}_${currentExerciseId}`}
                seconds={exerciseMinutes * 60}
                nextExercise={nextExercise}
                isPaused={isPaused}
                isMuted={isMuted}
              />
            </IonButton>
          )}
          {!!currentExerciseId && (
            <IonButton
              color={'dark'}
              onClick={toggleIsMuted}
              className={styles.headerButton}
            >
              <Icon
                slot={'icon-only'}
                name={isMuted ? 'volumeMuteOutline' : 'volumeHighOutline'}
              />
            </IonButton>
          )}
          {!!currentExerciseId && (
            <IonButton
              color={'dark'}
              onClick={toggleFullScreen}
              className={styles.headerButton}
              disabled={!isDesktop && (
                (!fullScreen && screenOrientation !== PORTRAIT_PRIMARY) ||
                (fullScreen && screenOrientation !== LANDSCAPE_PRIMARY)
              )}
            >
              <Icon
                slot={'icon-only'}
                name={fullScreen ? 'contract' : 'expand'}
              />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
});