import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const client = new QueryClient();

export const Provider: React.FC = ({ children }) => (
  <QueryClientProvider client={client}>
    {children}
  </QueryClientProvider>
);
