import React from 'react';
import { FieldInputProps, useField } from 'formik';
import { TextAreaProps } from './types';
import { IonItem, IonLabel, IonText, IonTextarea } from '@ionic/react';

const toTextAreaProps = (field: FieldInputProps<any>, props: TextAreaProps) => {
  return {
    value: field.value as string,
    type: props.type,
    onIonChange: field.onChange,
    name: field.name,
  };
};

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const [field, meta] = useField<{}>(props);
  const formattedProps = toTextAreaProps(field, props);
  return (
    <IonItem>
      {props.label && <IonLabel position="floating">{props.label}</IonLabel>}
      <IonTextarea {...formattedProps} />
      {!!meta.error && meta.touched && (
        <IonText color="danger">{meta.error}</IonText>
      )}
    </IonItem>
  );
};
