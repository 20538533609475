import React from 'react';
import classNames from 'classnames';
import { isPlatform, IonIcon } from '@ionic/react';
import { IconProps } from './types';
import { defaultIconSet, icons, iosIcons, mdIcons } from 'theme/config';
const ionIcons = require('ionicons/icons');

const ION_ICON = 'ion-icon';
const IMG = 'img';

export const Icon: React.FC<IconProps> = ({
  iconSet,
  name,
  ios,
  md,
  size = 'small',
  src,
  alt = '',
  width,
  height,
  color,
  slot,
  className,
}) => {
  if (!name && !src) return null;

  if (!iconSet) {
    if (!!defaultIconSet) {
      iconSet = defaultIconSet;
    } else {
      iconSet = ION_ICON;
    }
  }

  let iconName = name;
  let iconIos = ios;
  let iconMd = md;
  if (!!name && !!icons && !!icons[name]) {
    iconName = icons[name];
  }
  if (!!ios && !!iosIcons && !!iosIcons[ios]) {
    iconIos = iosIcons[ios];
  }
  if (!!md && !!mdIcons && !!mdIcons[md]) {
    iconMd = mdIcons[md];
  }

  // Add support for choosing device specific icon for other iconSets as well
  if (iconSet !== ION_ICON && (!!ios || !!md)) {
    if (isPlatform('ios')) {
      iconName = iconIos;
    }
    if (isPlatform('android')) {
      iconName = iconMd;
    }
  }

  const ionIcon: any =
    !!iconName && !!ionIcons[iconName] ? ionIcons[iconName] : iconName;
  const ionIconIos: any =
    !!iconIos && !!ionIcons[iconIos] ? ionIcons[iconIos] : iconIos;
  const ionIconMd: any =
    !!iconMd && !!ionIcons[iconMd] ? ionIcons[iconMd] : iconMd;

  switch (iconSet) {
    case ION_ICON:
      return (
        <IonIcon
          size={size}
          icon={ionIcon}
          src={iconName || src}
          ios={ionIconIos}
          md={ionIconMd}
          className={className}
          color={color}
          {...(!!slot ? {slot} : {})}
        />
      );
    case IMG:
      return (
        <img
          src={iconName || src}
          width={width}
          height={height}
          alt={alt}
          className={className}
        />
      );
    default:
      return <i className={classNames(`${iconSet} ${iconName}`, className)} />;
  }
};
