import styles from './YouTubeBackground.module.scss';
import React, {useRef, useEffect} from 'react';
import classNames from 'classnames';
import { YouTubeBackgroundProps } from './types';

export const YouTubeBackground: React.FC<YouTubeBackgroundProps> = (
  {
    src,
    objectFit = 'cover',
    className,
  },
) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const css = `
      .ytp-title-channel,
      .ytp-title,
      .ytp-chrome-top-buttons,
      .ytp-pause-overlay,
      .ytp-watermark {
        display: none !important;
      }
    `;

    const style = document.createElement('style');
    style.textContent = css;

    setTimeout(() => {
      // @ts-ignore
      iframeRef.current?.contentWindow?.document.head.appendChild(style);
    }, 2000)
  }, []);

  return (
    <iframe
      // ref={iframeRef}
      width={'100%'}
      title={src}
      src='https://www.youtube.com/embed/6-Y_ynfgBK0?autoplay=1&disablekb=1&loop=1&modestbranding=1&playsinline=1&mute=1&showinfo=0&widget_referrer=cornelius.app&enablejsapi=1&origin=http://localhost:3000'
      frameBorder='0'
      allowFullScreen
      className={classNames(
        {
          [styles.fitContain]: objectFit === 'contain',
        },
        styles.video,
        className,
      )}
    />
  );
};
