import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { AddExerciseForm } from './Form';

const AddExercisePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('Add exercise')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <AddExerciseForm />
      </IonContent>
    </IonPage>
  );
};
export default AddExercisePage;
