import {
  IonPage,
} from "@ionic/react";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { DataLoader } from "data/graphql/DataLoader";
import { getItemQuery } from "data/graphql/queries";
import { ItemPageContent } from "components/ItemPageContent";

const ItemPage: React.FC<{ itemType: string, id?: string }> = React.memo((
  {
    itemType
  }
) => {
  const history = useHistory();
  const { id } = useParams();
  let query = getItemQuery(itemType)(id);

  return (
    <IonPage>
      <DataLoader queryKey={itemType} variables={{id}} query={query}>
        {(props: any) => !props.data || !props.data[itemType] ? null : (
          <ItemPageContent
            pageLoaded={props.pageLoaded}
            key={`${id}_${history?.location?.key}`}
            id={id}
            itemType={itemType}
            {...props.data[itemType]}
          />
        )}
      </DataLoader>
    </IonPage>
  );
});
export default ItemPage;
