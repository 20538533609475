import "theme/main.scss";
import React, { /*useState*/ } from "react";
import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Redirect, Route } from "react-router-dom";
import { Menu } from "components/Menu";
import LandingPage from "pages/LandingPage";
import SignInPage from "pages/SignInPage";
import TrainerPage from "pages/TrainerPage";
import AddExercisePage from "adminPages/AddExercisePage";
import ItemsPage from "pages/ItemsPage";
import ItemPage from "pages/ItemPage";
import TermsPage from "pages/TermsPage";
import PrivacyPage from "pages/PrivacyPage";
import { usePageViews } from "./helpers/tracking";

const WorkoutsPage = () => <ItemsPage listType={"workouts"} itemType={"workout"}/>;
const WeeklyWorkoutsPage = () => <ItemsPage listType={"weekly-workouts"} itemType={"workout"}/>;
const ExercisesPage = () => <ItemsPage listType={"exercises"} itemType={"workout"}/>;
const MealsPage = () => <ItemsPage listType={"meals"} itemType={"meal"}/>;
const TipsPage = React.memo(() => <ItemsPage listType={"tips"} itemType={"tip"}/>);

const WorkoutPage = () => <ItemPage itemType={"workout"}/>;
const MealPage = () => <ItemPage itemType={"meal"}/>;
const TipPage = React.memo(() => <ItemPage itemType={"tip"}/>);
const CookiesPage = () => <PrivacyPage scrollToCookies={true}/>;


const App: React.FC<{ firstTimeUser: boolean; }> = React.memo((
  {
    firstTimeUser
  }
) => {
  usePageViews();
  // const [isSignedIn /*setIsSignedIn*/] = useState(false);

  const isNativePlatform = Capacitor.isNativePlatform();
  let defaultURL = '/weekly-workouts';
  if (firstTimeUser) {
    defaultURL = !isNativePlatform ? '/landing' : '/intro';
  }

  return (
    <IonSplitPane contentId='main' when={'xl'}>
      <Menu/>
      <IonRouterOutlet id='main'>
        <Route path='/landing' component={LandingPage} exact/>
        <Route path='/intro' component={SignInPage} exact/>
        <Route path='/trainer' component={TrainerPage} exact/>

        <Route path='/workouts' component={WorkoutsPage} exact/>
        <Route path='/weekly-workouts' component={WeeklyWorkoutsPage} exact/>
        <Route path='/exercises' component={ExercisesPage} exact/>
        <Route path='/tips' component={TipsPage} exact/>
        <Route path='/meals' component={MealsPage} exact/>

        <Route path='/workout/:id' component={WorkoutPage} exact/>
        <Route path='/meal/:id' component={MealPage} exact/>
        <Route path='/tip/:id' component={TipPage} exact/>

        <Route path='/terms' component={TermsPage} exact/>
        <Route path='/cookie-policy' component={CookiesPage} exact/>
        <Route path='/privacy' component={PrivacyPage} exact/>

        <Route path='/admin/exercise' component={AddExercisePage} exact/>
        <Redirect from='/' to={defaultURL} exact/>
      </IonRouterOutlet>
    </IonSplitPane>
  );
});

export default App;
