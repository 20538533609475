export enum TEXT_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface IntroSlideProps {
  imageSrc?: string;
  children?: React.ReactNode; // text content
  textPosition?: TEXT_POSITION;
  className?: string;
}
