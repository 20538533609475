import styles from './PrivacyPage.module.scss';
import React, {useEffect, useRef} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';

const PrivacyPage: React.FC<{scrollToCookies?: boolean;}> = ({scrollToCookies = false}) => {
  const { t } = useTranslation();
  const cookiesRef = useRef(null as unknown as HTMLDivElement);

  useEffect(() => {
    if (scrollToCookies) {
      setTimeout(() => {
        cookiesRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [scrollToCookies]);

  return (
    <IonPage className={'safe-padding'}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {t('Privacy Policy')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.container}>
        <div className={'size-5-padding-horizontal body max-width'}>
          <div className='div-block-37'>
            <div className='rt-info w-richtext'><p>Web Wizard Solutions SRL
              (<strong>“Web Wizard Solutions”</strong>, <strong>“we”</strong>, <strong>“our”</strong>, or <strong>“us”</strong>) is
              committed to protecting your privacy. This Privacy Policy explains how your personal information is collected,
              used, and disclosed by Web Wizard Solutions. This Privacy Policy applies to our websites including cornelius.app and any
              other website that links to this Policy (our “Sites”), and our mobile apps for iPhone, iPad, MacOs, or Android
              mobile
              devices, including the Cornelius mobile app (collectively, our “Service”). By accessing or using our Service,
              you
              signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your
              personal
              information as described in this Privacy Policy and our Terms of Service.</p><h2>1. WHAT INFORMATION DO WE
              COLLECT
              AND FOR WHAT PURPOSE?</h2><p>The categories of information we collect can include:</p>
              <ul>
                <li>Information you provide to us directly. We may collect personal information, such as your name, phone
                  number, and location when you register for our Service, or otherwise communicate with us. We may also
                  collect
                  any communications between you and Web Wizard Solutions and any other information you provide to Web Wizard Solutions.
                </li>
                <li>Voice recordings and transcription. With your permission, we collect voice recordings and request access
                  to
                  the microphone on your mobile device in order to facilitate recordings for the Service. These recordings
                  will
                  automatically transcribe text into the voice recording tile within each “care room.” You may withdraw this
                  permission to access your device’s microphone at any time from the ‘Settings’ app of your mobile device.
                  You
                  agree to comply with all applicable laws, whether federal, state, local or international, relating to the
                  privacy of communication for all parties to a conversation, including without limitation, advising all
                  participants in a recorded conversation that the conversation is being recorded and obtaining their
                  consent,
                  as required.
                </li>
                <li>Data collected through the use of the Service. We collect information about how you use the Service,
                  your
                  actions on the Service, and content you post to the Service, including your interaction with friends and
                  others on the Service, voice recordings and photos you post to the Service, and any content you provide
                  through in-app messages, or other functionality (“User Content”). To share photos, we will access your
                  device
                  camera with your permission. If you need to update your permissions, you can do so in the “Settings” app
                  of
                  your device. Please remember that Web Wizard Solutions may, but has no obligation to, record and store User Content
                  in
                  order to assist with regulatory or law enforcement efforts, or to protect and defend our rights and
                  property.
                  By using the Service, you consent to the recording, storage, and disclosure of such communications you
                  send or
                  receive for these purposes.
                </li>
                <li>Address Book Information. You may wish to connect your account with those of your friends and family.
                  With
                  your permission, we may request access to your contact list available on your mobile device so that you
                  can
                  invite your friends and family to connect with you on the Service. When you send an invitation to your
                  friends
                  to join a “care room” through the Service, you will share with our servers the phone number and contact
                  name
                  for the individual you would like to invite in order to allow our service to send a customized SMS message
                  to
                  the person who was invited to join your room. We only send to the server the phone number and name of
                  invited
                  contacts so that others in the room can identify who these people are. We do not upload other information
                  about these contacts or information about contacts other than those invited to your room.
                </li>
                <li>Location Information. We collect information about your location through GPS, Wi-Fi, wireless network
                  triangulation, or other methods in order to obtain your location for the purposes of providing our
                  Service. We
                  may also approximate your location by your IP Address. We use your location data in order to let you share
                  your location with others, remember where you made recordings, and to help you select which office you
                  were in
                  and what doctor you were meeting with, as well for us to understand where our services are being used in
                  aggregate. We may also need to enable or disable portions of our service depending on your location to
                  comply
                  with local regulations.
                </li>
              </ul>
              <p>We use this information to operate, maintain, and provide to you the features and functionality of the
                Service,
                as well as to communicate directly with you, such as to permit you to communicate with others on the
                Service,
                invite others to join the Service, or to send you surveys and promotional communications. We may also send
                you
                Service-related messages (e.g., account verification, updates to features of the Service, technical and
                security
                notices).</p><p>We may also use your information to understand our visitors and customers, and tailor our
                product offerings. We analyze statistics and trends to make our products better and to develop new services
                or
                features. We may also use your information to inform our machine learning algorithms to enhance and/or
                customize
                our Services.</p><p>For more information about your communication preferences, see “Control Over Your
                Information” below.<br /></p></div>
            <div id='cookie' className='rt-info w-richtext' ref={cookiesRef}>
              <h2>2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO
              COLLECT
              INFORMATION</h2><p>We, and our third-party partners, automatically collect certain types of usage information
              when
              you visit our Service, read our emails, or otherwise engage with us. We typically collect this information
              through
              a variety of tracking technologies, including cookies, web beacons, embedded scripts, location-identifying
              technologies, file information, and similar technology (collectively, “tracking technologies”). For example,
              we
              collect information about your device and its software, such as your IP address, browser type, Internet
              service
              provider, platform type, device type, operating system, date and time stamp (a unique ID that allows us to
              uniquely identify your browser, mobile device, or your account), and other such information. We also collect
              information about the way you use our Service, for example, the site from which you came and the site to which
              you
              are going when you leave our website and other actions you take on the Service. When you access our Service
              from a
              mobile device, we may collect unique identification numbers associated with your device or our mobile
              application
              (including, for example, a UDID, Unique ID for Advertisers (“IDFA”), Google AdID, or Windows Advertising ID),
              mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, phone
              number,
              and, depending on your mobile device settings, your geographical location data, including GPS coordinates
              (e.g.,
              latitude and/or longitude) or similar information regarding the location of your mobile device, or we may be
              able
              to approximate a device’s location by analyzing other information, like an IP address. We may collect
              analytics
              data or use third-party analytics tools such as Google Analytics to help us measure traffic and usage trends
              for
              the Service and to understand more about the demographics of our users. You can learn more about Google’s
              practices at <a
                href='http://www.google.com/policies/privacy/partners'>http://www.google.com/policies/privacy/partners</a> and
              view its currently available opt-out options at <a
                href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>. We may also
              work
              with third-party partners to employ technologies, including the application of statistical modeling tools,
              which
              permit us to recognize and contact you across multiple devices. Although we do our best to honor the privacy
              preferences of our users, we are unable to respond to Do Not Track signals set by your browser at this time.
            </p>
              <p>We use or may use the data collected through tracking technologies to: (a) remember information so that you
                will not have to re-enter it during your visit or the next time you visit the site; (b) provide custom,
                personalized content and information, including targeted content and advertising; (c) recognize and contact
                you
                across multiple devices; (d) provide and monitor the effectiveness of our Service; (e) monitor aggregate
                metrics
                such as total number of visitors, traffic, usage, and demographic patterns on our Service; (f) diagnose or
                fix
                technology problems; and (g) otherwise to plan for and enhance our Service.</p><p>If you would prefer not to
                accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you
                receive
                a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set
                your
                browser to automatically reject cookies. Please note that doing so may negatively impact your experience
                using
                the Service, as some features and services on our Service may not work properly. Depending on your mobile
                device
                and operating system, you may not be able to delete or block all cookies. You may also set your email
                options to
                prevent the automatic downloading of images that may contain technologies that would allow us to know
                whether
                you have accessed our email and performed certain functions with it.</p><p>We and our third-party partners
                may
                also use cookies and tracking technologies for advertising purposes. For more information about tracking
                technologies, please see “Third Party Tracking and Online Advertising” below.</p><h2>3. SHARING OF YOUR
                INFORMATION</h2><p>We may share your personal information in the instances described below. For further
                information on your choices regarding your information, see the “Control Over Your Information” section
                below.</p><p>Remember, our Service allows you to connect and interact with others. Your profile information,
                which is limited to your first name and last name, will be available to other individuals when you interact
                with
                others on the Service through, for example, a virtual “care room,” and post content.</p><p>We may share your
                personal information with:</p>
              <ul>
                <li>The public or other users of the Service at your request. We share your information and content you post
                  with third parties with whom you communicate on the Service. For example, you may have the option to share
                  your information and recordings with your friends through the use of the virtual “care room.” You control
                  who
                  you want to communicate with and what information you share. Please do not provide personal information or
                  content you would not want to be viewed by those invited to join your virtual “care room.” You may be able
                  to
                  control the visibility of some of your information and actions in your Settings (see “Control Over Your
                  Information” below);
                </li>
                <li>Other companies and brands owned or controlled by Web Wizard Solutions, and other companies owned by or under
                  common
                  ownership as Web Wizard Solutions, which also includes our subsidiaries (i.e., any organization we own or control) or
                  our
                  ultimate holding Web Wizard Solutions (i.e., any organization that owns or controls us) and any subsidiaries it owns.
                  These companies will use your personal information in the same way as we can under this Policy;
                </li>
                <li>Third-party vendors and other service providers that perform services on our behalf, as needed to carry
                  out
                  their work for us, which may include identifying and serving targeted advertisements, providing mailing
                  services, providing tax and accounting services, web hosting, or providing analytic services;
                </li>
                <li>Third parties with your consent. For example, with your consent, we may share your information with
                  third
                  parties to conduct research;
                </li>
                <li>Other parties in connection with a Web Wizard Solutions transaction, such as a merger, sale of Web Wizard Solutions assets or
                  shares, reorganization, financing, change of control or acquisition of all or a portion of our business by
                  another Web Wizard Solutions or third party, or in the event of a bankruptcy or related or similar proceedings;
                </li>
                <li>Third parties as required by law or subpoena or if we reasonably believe that such action is necessary
                  to
                  (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce our Terms of
                  Service or
                  to protect the security or integrity of our Service; and/or (c) to exercise or protect the rights,
                  property,
                  or personal safety of Web Wizard Solutions, our visitors, or others.
                </li>
              </ul>
              <p>We may also share information with others in an aggregated or otherwise anonymized form, including
                licensing to
                third parties, conversational recordings and transcriptions from which all personal identifiers have been
                removed. The data may be used for development of AI solutions including conversational AI. You have a right
                to
                opt out of the sharing of your information for this purpose; please notify us by writing to <a
                  href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a> with subject line 'Data Opt Out' and your
                cell
                phone number associated with your Web Wizard Solutions account if you do not want your information used in this way.
              </p>
              <p>Web Wizard Solutions complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set
                forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal
                information
                transferred from the European Union and Switzerland to the United States. Web Wizard Solutions has certified to the
                U.S.
                Department of Commerce that it adheres to the Privacy Shield Principles. &nbsp;If there is any conflict
                between
                the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall
                govern. &nbsp;To learn more about the Privacy Shield program, and to view our certification, please visit <a
                  href='https://www.privacyshield.gov/'>https://www.privacyshield.gov/</a>&zwj;</p><p>In compliance with the
                Privacy Shield Principles, Web Wizard Solutions commits to resolve complaints about our collection or use of your
                personal
                information. &nbsp;EU and Swiss individuals with inquiries or complaints regarding our Privacy Shield policy
                should first contact Web Wizard Solutions at: Web Wizard Solutions Privacy, <a
                  href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a>, Calea 13 Septembrie 59-61, Bucharest, Romania.
              </p><p>Web Wizard Solutions has further committed to refer unresolved Privacy Shield complaints to the EU&nbsp;Data
                Protection Authorities (DPA), an alternative dispute resolution provider located in the European Union. If
                you
                do not receive timely acknowledgment of your complaint from us, or if we have not addressed your complaint
                to
                your satisfaction, please visit https://edpb.europa.eu/ for more information or to file a
                complaint. &nbsp;The
                services of DPA are provided at no cost to you.</p><p>&zwj;</p><h2>4. CONTROL OVER YOUR INFORMATION</h2>
              <p>Profile and Data Sharing Settings. You may update your profile information, such as your name, and may
                change
                some of your data sharing preferences on your Profile Settings page.</p><p>Access to your Device
                Information.
                You may control the Service’s access to your device information through your “Settings” app on your device.
                For
                instance, you can withdraw permission for the Service to access your address book, microphone, location, and
                camera.</p><p>How to control your communications preferences. You can stop receiving promotional email
                communications from us by clicking on the “unsubscribe link” provided in such communications. We make every
                effort to promptly process all unsubscribe requests. You may not opt out of service-related communications
                (e.g., account verification, transactional communications, changes/updates to features of the Service,
                technical
                and security notices).</p><p>Modifying or deleting your information. If you have any questions about
                reviewing,
                modifying, or deleting your information, or if you want to remove your name or comments from our Service or
                publicly displayed content, you can contact us directly at <a
                  href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a>. We may not be able to modify or delete your
                information in all circumstances.</p><h2>5. THIRD-PARTY TRACKING AND ONLINE ADVERTISING</h2>
              <p>Interest-Based
                Advertising. We participate in interest-based advertising and use third-party advertising companies to serve
                you
                targeted advertisements based on your browsing history. We may share or we may permit third-party online
                advertising networks, social media companies, and other third-party services to collect information about
                your
                use of our website over time so that they may play or display ads on our Service, on other devices you may
                use,
                and on other websites, apps, or services including Facebook. Typically, though not always, the information
                we
                share is provided through cookies or similar tracking technologies, which recognize the device you are using
                and
                collect information, including click stream information, browser type, time and date you visited the site,
                and
                other information. We and our third-party partners use this information to make the advertisements you see
                online more relevant to your interests, as well as to provide advertising-related services such as
                reporting,
                attribution, analytics, and market research.</p><p>Cross-Device Linking. We, or our third-party partners,
                may
                link your various devices so that content you see on one device can result in relevant advertising on
                another
                device. We do this by collecting information about each device you use when you are logged in to our
                Service. We
                may also work with third-party partners who employ tracking technologies or the application of statistical
                modeling tools to determine if two or more devices are linked to a single user or household. We may share a
                common account identifier (such as an email address or user ID) with third-party advertising partners to
                help
                recognize you across devices. We, and our partners, can use this cross-device linkage to serve
                interest-based
                advertising and other personalized content to you across your devices, to perform analytics, and to measure
                the
                performance of our advertising campaigns.</p><p>Your Choices:</p>
              <ul>
                <li>Interest-based advertising. To learn about interest-based advertising and how you may be able to opt-out
                  of
                  some of this advertising, you may wish to visit the Network Advertising Initiative’s online resources
                  at <a
                    href='http://www.networkadvertising.org/choices'>http://www.networkadvertising.org/choices</a> and/or
                  the
                  DAA’s resources at www.aboutads.info/choices.
                </li>
                <li>Cross-device linking. Please note that opting-out of receiving interest-based advertising through the
                  NAI’s
                  and DAA’s online resources will only opt-out a user from receiving interest-based ads on that specific
                  browser
                  or device, but the user may still receive interest-based ads on his or her other devices. You must perform
                  the
                  opt-out on each browser or device you use.
                </li>
                <li>Mobile advertising. You may also be able to limit interest-based advertising through the settings on
                  your
                  mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest based ads” (Android). You may
                  also be able to opt-out of some—but not all—interest-based ads served by mobile ad networks by visiting <a
                    href='http://youradchoices.com/appchoices'>http://youradchoices.com/appchoices</a> and downloading the
                  mobile AppChoices app.
                </li>
                <li>Some of these opt-outs may not be effective unless your browser is set to accept cookies. If you delete
                  cookies, change your browser settings, switch browsers or computers, or use another operating system, you
                  will
                  need to opt-out again.
                </li>
              </ul>
              <p>Google Analytics and Advertising. We use Google Analytics to recognize you and link the devices you use
                when
                you visit our site or Service on your browser or mobile device, log in to your account on our Service, or
                otherwise engage with us. We share a unique identifier, like a user ID or hashed email address, with Google
                to
                facilitate the service. Google Analytics allows us to better understand how our users interact with our
                Service
                and to tailor our advertisements and content to you. For information on how Google Analytics collects and
                processes data, as well as how you can control information sent to Google, review Google\'s site “How Google
                uses data when you use our partners’ sites or apps” located at www.google.com/policies/privacy/partners/.
                You
                can learn about Google Analytics’ currently available opt-outs, including the Google Analytics Browser Ad-On
                here <a href='https://tools.google.com/dlpage/gaoptout/'>https://tools.google.com/dlpage/gaoptout/.</a></p>
              <p>'We may also utilize certain forms of display advertising and other advanced features through Google
                Analytics,
                such as Remarketing with Google Analytics, Google Display Network Impression Reporting, the DoubleClick
                Campaign
                Manager Integration, and Google Analytics Demographics and Interest Reporting. These features enable us to
                use
                first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick
                advertising cookie) or other third-party cookies together to inform, optimize, and display ads based on your
                past visits to the Service. You may control your advertising preferences or opt-out of certain Google
                advertising products by visiting the Google Ads Preferences Manager, currently available at <a
                  href='https://google.com/ads/preferences'>https://google.com/ads/preferences</a>, or by visiting NAI’s
                online
                resources at <a
                  href='http://www.networkadvertising.org/choices'>http://www.networkadvertising.org/choices</a>.
              </p><h2>6. HOW WE STORE AND PROTECT YOUR INFORMATION</h2><p>Data storage and transfer: Your information
                collected
                through our Service may be stored and processed in the United States or any other country in which we or our
                parent, subsidiaries, affiliates, or service providers maintain facilities. If you are located in other
                regions
                with laws governing data collection and use that may differ from U.S. law, please note that we may transfer
                information, including personal information, to a country and jurisdiction that does not have the same data
                protection laws as your jurisdiction.</p><p>Keeping your information safe: We care about the security of
                your
                information and employ physical, administrative, and technological safeguards designed to preserve the
                integrity
                and security of all information collected through our Service. However, no security system is impenetrable,
                and
                we cannot guarantee the security of our systems 100%. In the event that any information under our control is
                compromised as a result of a breach of security, we will take reasonable steps to investigate the situation
                and,
                where appropriate, notify those individuals whose information may have been compromised and take other
                steps, in
                accordance with any applicable laws and regulations.</p><p>The data that Web Wizard Solutions collects and the way we
                process information makes our company subject to the investigatory and enforcement powers of the Federal
                Trade
                Commission (FTC). &nbsp;Under certain conditions, you may be able to invoke arbitration with Web Wizard Solutions that
                binds the company to the findings and agreements therewith. </p><p>&zwj;</p><h2>7. CHILDREN’S PRIVACY</h2>
              <p>Web Wizard Solutions does not knowingly collect or solicit any information from anyone under the age of 13 on this
                Service. In the event that we learn that we have inadvertently collected personal information from a child
                under
                age 13, we will delete that information as quickly as possible. If you believe that we might have any
                information from a child under 13, please contact us at <a
                  href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a>.</p><h2>8. LINKS TO OTHER WEB SITES AND
                SERVICES</h2><p>The Service may contain links to and from third-party websites of our business partners,
                advertisers, and social media sites and our users may post links to third-party websites. If you follow a
                link
                to any of these websites, please note that these websites have their own privacy policies and that we do not
                accept any responsibility or liability for their policies. We strongly recommend that you read their privacy
                policies and terms and conditions of use to understand how they collect, use, and share information. We are
                not
                responsible for the privacy practices or the content on the websites of third-party sites.</p><h2>9. CHANGES
                TO
                OUR PRIVACY POLICY</h2><p>We may modify or update this Privacy Policy from time to time to reflect the
                changes
                in our business and practices, so you should review this page periodically. When we change the policy in a
                material manner, we will let you know and update the ‘last modified’ date at the bottom of this page. If you
                object to any changes, you may close your account. Continuing to use our Service after we publish changes to
                this Privacy Policy means that you are consenting to the changes.</p><h2>10. HOW TO CONTACT US</h2><p>If you
                have any questions about this Privacy Policy or the Service, please contact us at <a
                  href='mailto:office@webwizard.solutions'>office@webwizard.solutions</a>.</p><p>This Privacy Policy was last modified
                on
                September 21, 2021.<br /></p></div>
          </div>
        </div>
        <Footer/>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPage;