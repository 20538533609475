import styles from "./TrainerPage.module.scss";
import React from 'react';
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  IonPage,
  IonHeader,
  IonFooter,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
} from '@ionic/react';
import * as Text from "components/Text";
import { Icon } from "components/Icon";
import { DataLoader } from "data/graphql/DataLoader";
import { trainerQuery } from "data/graphql/queries";
import { CertificationType, SpecializationType } from "./types";
import { ImageBackground } from '../../components/ImageBackground';

const TrainerPage: React.FC = () => {
  const { t } = useTranslation();
  const id = "1";
  let query = trainerQuery(id);

  return (
    <IonPage>
      <IonHeader className={"ion-no-border"}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
          <IonTitle>{t("Your trainer")}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color={'primary'}
              fill={'clear'}
              target={'_blank'}
              rel={'external'}
              href={'https://api.whatsapp.com/send?phone=40761544317&text=Salut&source=&data=&app_absent='}
            >
              <Icon size={'large'} name={'logoWhatsapp'} slot={'icon-only'}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <DataLoader queryKey={"trainer"} variables={{ id }} query={query}>
        {(props: any) => {
          const trainer = props?.data?.["trainer"];
          if (!trainer) return null;
          return (
            <IonContent fullscreen>
              <IonGrid className={'no-padding'}>
                <IonRow>
                  <IonCol size={'12'} size-md={6} className={'no-padding'}>
                    <section className={classNames(styles.topSection, "size-5-padding-horizontal")}>
                      <div className={styles.photo}>
                        <ImageBackground
                          src={props.data["trainer"]["mainPhoto"]?.["url"]}
                          objectFit={'contain'}
                        />
                      </div>
                      <h1 className={"ion-text-center"}>
                        <Text.ExtraLarge><strong>{trainer.firstName} {trainer.lastName}</strong></Text.ExtraLarge>
                      </h1>
                    </section>
                  </IonCol>
                  <IonCol size={'12'} size-md={6} className={'no-padding'}>
                    <main className={"size-5-padding-horizontal"}>
                      {!!trainer.about && <p><Text.Body>{trainer.about}</Text.Body></p>}

                      {!!trainer.certifications?.length && (
                        <>
                          <h2><Text.LargeAndBold>{t("My certifications")}</Text.LargeAndBold></h2>
                          <IonList>
                            {trainer.certifications
                              .filter((certification: CertificationType) => !!certification.name)
                              .map((certification: CertificationType, index: number) => (
                                <IonItem lines={"none"} key={index}>
                                  <Icon size={'medium'} slot={"start"} name={"trophy"}/>
                                  <Text.Body className={'size-1-margin-vertical'}>{certification.name.trim()}</Text.Body>
                                </IonItem>
                              ))}
                          </IonList>
                        </>
                      )}
                      {!!trainer.specializations?.length && (
                        <>
                          <h2><Text.LargeAndBold>{t("My specializations")}</Text.LargeAndBold></h2>
                          <IonList>
                            {trainer.specializations
                              .filter((specialization: SpecializationType) => !!specialization.name)
                              .map((specialization: SpecializationType, index: number) => (
                                <IonItem lines={"none"} key={index}>
                                  <Icon size={'medium'} slot={"start"} name={"award"}/>
                                  <Text.Body className={'size-1-margin-vertical'}>{specialization.name.trim()}</Text.Body>
                                </IonItem>
                              ))}
                          </IonList>
                        </>
                      )}
                    </main>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          );
        }}
      </DataLoader>
      <IonFooter className={classNames(styles.footer, 'ion-no-border ion-text-center size-5-padding-horizontal size-8-padding-top size-5-padding-bottom')}>
        <IonButton className={styles.ctaButton} routerLink={'/weekly-workouts'} routerDirection={'root'} expand={"block"} shape={"round"}>
          {t("See workouts").toUpperCase()}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
export default TrainerPage;
