import styles from './Author.module.scss';
import React from 'react';
import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import { AuthorProps } from './types';
import { IMAGE_PLACEHOLDER } from 'theme/config';
import * as Text from 'components/Text';
import { ImageBackground } from 'components/ImageBackground';

export const Author: React.FC<AuthorProps> = ({
  name,
  title,
  photo,
  size = 'small',
  className,
}) => {
  if (size === 'large') {
    return (
      <div className={classNames(styles.container, styles.large, className)}>
        <IonAvatar className={styles.photoContainer}>
          <ImageBackground src={photo || IMAGE_PLACEHOLDER} />
        </IonAvatar>
        <IonLabel className={'size-2-margin-bottom'}>
          {title && (
            <p>
              <Text.Smaller className={styles.title}>{title}</Text.Smaller>
            </p>
          )}
          {name && (
            <h3>
              <Text.LargeAndBold className={styles.name}>
                {name}
              </Text.LargeAndBold>
            </h3>
          )}
        </IonLabel>
      </div>
    );
  }
  return (
    <IonItem
      lines="none"
      className={classNames(styles.container, styles.small, className)}
    >
      <IonAvatar
        className={classNames(styles.photoContainer, 'size-4-margin-end')}
      >
        <ImageBackground src={photo || IMAGE_PLACEHOLDER} />
      </IonAvatar>
      <IonLabel>
        {title && (
          <p>
            <Text.Smaller className={styles.title}>{title}</Text.Smaller>
          </p>
        )}
        {name && (
          <h3>
            <Text.BiggerAndBold className={styles.name}>
              {name}
            </Text.BiggerAndBold>
          </h3>
        )}
      </IonLabel>
    </IonItem>
  );
};
