import styles from "./CardItem.module.scss";
import React from "react";
import classNames from 'classnames';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  isPlatform,
} from "@ionic/react";
import { CardItemProps } from "./types";
// import { Author } from "components/Author";
// import { Divider } from "components/Divider";
import * as Text from "components/Text";
import { Tag } from "components/Tag";
import { Tags } from "components/Tags";
import { Media } from "components/Media";

const isDesktop = isPlatform('desktop');

export const CardItem: React.FC<CardItemProps> = React.memo((
  {
    itemType,
    id,
    title,
    thumbnail,
    media,
    level,
    minutes,
    type,
    cal,
  }
) => {
  return (
    <IonCard routerLink={`/${itemType}/${id}`} className={classNames(styles.cardItem, {[styles.grow]: isDesktop})}>
      <div className={styles.imageWrapper}>
        <Media mode={'media'} thumbnail={thumbnail} media={media} className={styles.itemImage}/>
        {!!title && (
          <IonCardHeader className={styles.itemTitle}>
            <IonCardTitle>
              <Text.LargerAndBold className={styles.itemTitleText}>{title}</Text.LargerAndBold>
            </IonCardTitle>
          </IonCardHeader>
        )}
      </div>
      <IonCardContent className={"hide-when-empty no-padding-bottom"}>
        {(!!level || !!minutes || !!type || !!cal) && (
          <Tags className="size-3-padding-bottom">
            {!!level && <Tag type="level" value={level} className={'size-2-margin-start size-3-margin-top'}/>}
            {!!minutes && itemType === 'workout' && <Tag type="workoutTime" value={minutes} className={'size-2-margin-start size-3-margin-top'}/>}
            {!!type && <Tag type="mealType" value={type} className={'size-2-margin-start size-3-margin-top'}/>}
            {!!minutes && itemType === 'meal' && <Tag type="mealTime" value="80" className={'size-2-margin-start size-3-margin-top'}/>}
            {!!cal && <Tag type="kCalories" value={cal} className={'size-2-margin-start size-3-margin-top'}/>}
          </Tags>
        )}
      </IonCardContent>
      {/*<IonCardContent className={styles.author}>*/}
      {/*  <Divider className="size-2-margin-bottom"/>*/}
      {/*  <Author*/}
      {/*    name="Cornel Duta"*/}
      {/*    title="Personal Trainer"*/}
      {/*    photo="https://cornelius-data-dev.s3.eu-central-1.amazonaws.com/dc238244180448e8b1949a4684f02ed6.jpg"*/}
      {/*  />*/}
      {/*</IonCardContent>*/}
    </IonCard>
  );
});
