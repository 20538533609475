import styles from './FileUpload.module.scss';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { IonButton, IonItem, IonLabel, IonText } from "@ionic/react";
import { FieldInputProps, useField } from 'formik';
import { Thumbnail } from "../../Thumbnail";

const toInputProps = (field: FieldInputProps<any>, inputProps: any) => {
  return {
    ...inputProps,
    name: field.name,
    value: field.value as string,
    type: 'file',
  };
};

export const FileUpload: React.FC<any> = ({label, ...props}) => {
  const [field, meta, helper] = useField<{}>(props);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!meta.touched) helper.setTouched(true);
      const file = acceptedFiles?.[0];
      if (!file) {
        return;
      }
      helper.setValue(file);
      // const blobUrl = URL.createObjectURL(file);
      // const name = `${file.name}_${Date.now()}`;
    },
    [helper, meta.touched]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });
  const formattedProps = toInputProps(field, getInputProps());
  const {value, ...inputProps} = formattedProps;

  return (
    <IonItem>
      <div className={styles.container}>
        <Thumbnail file={value}/>
        <div {...getRootProps()}>
          <div className={classNames(styles.dropTarget, {[styles.active]: isDragActive})}>
            <div className="d-flex ion-justify-content-between ion-align-items-center">
              <input {...inputProps} />
              <IonLabel>{label}</IonLabel>
              <IonButton strong color="dark">+</IonButton>
            </div>
          </div>
        </div>
      </div>
      {!!meta.error && meta.touched && (
        <IonText color="danger">{meta.error}</IonText>
      )}
    </IonItem>
  );
};
