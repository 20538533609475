import React, { ErrorInfo } from 'react';
import ReactGA from "react-ga";
import {Error} from 'components/Error';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  handleError() {
    window.location.reload();
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    ReactGA.event({
      category: 'Error',
      action: JSON.stringify(errorInfo),
      label: window.location.href,
    });
    console.log(errorInfo);
    setTimeout(this.handleError, 1000);
  }

  render() {
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return <Error/>;
    }

    return this.props.children;
  }
}