import React from 'react';
import { FieldInputProps, useField } from 'formik';
import { InputProps } from './types';
import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';

const toIonInputProps = (field: FieldInputProps<any>, props: InputProps) => {
  return {
    value: field.value as string,
    type: props.type,
    onIonChange: field.onChange,
    name: field.name,
  };
};

export const Input: React.FC<InputProps> = (props) => {
  const [field, meta] = useField<{}>(props);
  const formattedProps = toIonInputProps(field, props);
  return (
    <IonItem>
      {props.label && <IonLabel position="floating">{props.label}</IonLabel>}
      <IonInput {...formattedProps} />
      {!!meta.error && meta.touched && (
        <IonText color="danger">{meta.error}</IonText>
      )}
    </IonItem>
  );
};
