import styles from './Congratulations.module.scss';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IonImg } from '@ionic/react';
import laurels from 'assets/laurels.png';
import * as Text from 'components/Text';

export const Congratulations: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={'size-8-padding centered'}>
      <IonImg src={laurels} className={classNames(styles.image, 'size-4-margin-bottom')}/>
      <Text.ExtraLarge>{t('Congratulations!')}</Text.ExtraLarge>
      <Text.Large>{t('You finished the workout!')}</Text.Large>
    </div>
  );
};
