import styles from './VideoBackground.module.scss';
import React from 'react';
import classNames from 'classnames';
import { VideoBackgroundProps } from './types';
import placeholder from 'assets/placeholder.png';

export const VideoBackground: React.FC<VideoBackgroundProps> = (
  {
    src,
    objectFit = 'cover',
    className,
  },
) => {
  return (
    <video
      width={'100%'}
      poster={placeholder}
      autoPlay
      muted
      loop
      playsInline
      controls={false}
      className={classNames(
        {
          [styles.fitContain]: objectFit === 'contain',
        },
        styles.video,
        className,
      )}
    >
      <source src={src} type={'video/mp4'} />
      {/*<source src={src}/>*/}
    </video>
  );
};
