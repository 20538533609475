import styles from './EnergyContent.module.scss';
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EnergyContentProps } from './types';
import * as Text from 'components/Text';
import { SpinningDigit } from 'components/Animations/SpinningDigit';

enum MEASUREMENT_TYPES {
  PER_SERVING = 'PER_SERVING',
  PER_100G = 'PER_100G',
}

export const EnergyContent: React.FC<EnergyContentProps> = ({
  proteinPerServing,
  fatPerServing,
  saturatedFatPerServing,
  carbohydratesPerServing,
  sugarPerServing,
  fiberPerServing,

  proteinPer100g,
  fatPer100g,
  saturatedFatPer100g,
  carbohydratesPer100g,
  sugarPer100g,
  fiberPer100g,

  className,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isDefined = (element: any) => typeof element !== 'undefined';

  const perServingInfoIsDefined = [
    proteinPerServing,
    fatPerServing,
    saturatedFatPerServing,
    carbohydratesPerServing,
    sugarPerServing,
    fiberPerServing,
  ].some(isDefined);
  const per100gInfoIsDefined = [
    proteinPer100g,
    fatPer100g,
    saturatedFatPer100g,
    carbohydratesPer100g,
    sugarPer100g,
    fiberPer100g,
  ].some(isDefined);

  const [measurementType, setMeasurementType] = useState(
    perServingInfoIsDefined
      ? MEASUREMENT_TYPES.PER_SERVING
      : MEASUREMENT_TYPES.PER_100G
  );
  if (!perServingInfoIsDefined && !per100gInfoIsDefined) return null;

  const setMeasurementTypePerServing = (event: any): void => {
    event.preventDefault();
    setMeasurementType(MEASUREMENT_TYPES.PER_SERVING);
  };
  const setMeasurementTypePer100g = (event: any): void => {
    event.preventDefault();
    setMeasurementType(MEASUREMENT_TYPES.PER_100G);
  };

  const components = [
    {
      label: t('protein'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? proteinPerServing
          : proteinPer100g,
    },
    {
      label: t('fat'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? fatPerServing
          : fatPer100g,
    },
    {
      label: t('saturatedFat'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? saturatedFatPerServing
          : saturatedFatPer100g,
    },
    {
      label: t('carbs'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? carbohydratesPerServing
          : carbohydratesPer100g,
    },
    {
      label: t('sugar'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? sugarPerServing
          : sugarPer100g,
    },
    {
      label: t('fiber'),
      value:
        measurementType === MEASUREMENT_TYPES.PER_SERVING
          ? fiberPerServing
          : fiberPer100g,
    },
  ];
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.energyContent}>
        {components.map((component, index) => {
          const { label, value } = component;
          if (typeof value === 'undefined') return null;
          const parsedValue =
            typeof value === 'string' ? parseFloat(value) : value;
          const formattedValue = new Intl.NumberFormat(language, {
            maximumFractionDigits: 1,
          }).format(parsedValue);
          const animatedValue = formattedValue
            .split('')
            .map((char, charIndex) => {
              if (isNaN(parseInt(char, 10))) return char;
              return (
                <SpinningDigit
                  key={`EnergyContentDigit_${index}_${charIndex}`}
                  value={char}
                />
              );
            });
          return (
            <div key={`EnergyContent_${index}_${measurementType}`} className={styles.component}>
              <div className={styles.componentValue}>
                <Text.Body color="dark">{animatedValue}g</Text.Body>
              </div>
              <div className={styles.componentLabel}>
                <Text.Smaller>{label}</Text.Smaller>
              </div>
            </div>
          );
        })}
      </div>
      <ul className={styles.measurementTypes}>
        {perServingInfoIsDefined && (
          <li className={styles.measurementType}>
            {measurementType === MEASUREMENT_TYPES.PER_SERVING ? (
              <Text.Smaller>{t('per serving')}</Text.Smaller>
            ) : (
              <a href="#perServing" onClick={setMeasurementTypePerServing}>
                <Text.Smaller>{t('per serving')}</Text.Smaller>
              </a>
            )}
          </li>
        )}
        {per100gInfoIsDefined && (
          <li className={styles.measurementType}>
            {measurementType === MEASUREMENT_TYPES.PER_100G ? (
              <Text.Smaller>{t('per 100g')}</Text.Smaller>
            ) : (
              <a href="#per100g" onClick={setMeasurementTypePer100g}>
                <Text.Smaller>{t('per 100g')}</Text.Smaller>
              </a>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};
