import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { IonButton, IonSelectOption, IonItem } from "@ionic/react";
import { Form, Input, Select, TextArea, FileUpload } from "components/Forms";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  // age: yup.number().integer().min(13).max(120),
  // title: yup.string().required().max(50),
  // category: yup.string().required(),
  // description: yup.string().required()
});

export const AddExerciseForm: React.FC = () => {
  const { t } = useTranslation();

  const categories: { [key: string]: string } = {
    warm_up: t("Warm up"),
    back: t("Back"),
    biceps: t("Biceps"),
    cardio: t("Cardio"),
    chest: t("Chest"),
    core: t("Core"),
    legs: t("Legs"),
    shoulders: t("Shoulders"),
    triceps: t("Triceps"),
    lower_body: t("Lower body"),
    upper_body: t("Upper body"),
    full_body: t("Full body")
  };

  const handleSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
    >
      {(values: any) => (
        <Form className="min-h-100 d-flex flex-1 direction-column ion-justify-content-between compact">
          <div>
            <Input name="title" label={t("Title")} type="text"/>
            <Select name="category" label={t("Category")}>
              {Object.keys(categories).map((value, index) => (
                <IonSelectOption key={`category-option-${index}`} value={value}>
                  {categories[value]}
                </IonSelectOption>
              ))}
            </Select>
            <TextArea name="description" label={t("Description")}/>
            <FileUpload name="image" label={t('Video')}/>
          </div>

          <IonItem lines="none">
            <IonButton type="submit" size="default" strong className="w-100">{t("Save")}</IonButton>
          </IonItem>
          {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
        </Form>
      )}
    </Formik>
  );
};
