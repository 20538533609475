import styles from './IntroSlide.module.scss';
import React from 'react';
import classNames from 'classnames';
import { IntroSlideProps, TEXT_POSITION } from './types';

export const IntroSlide: React.FC<IntroSlideProps> = ({
  imageSrc,
  children,
  textPosition = TEXT_POSITION.TOP,
  className,
}) => {
  return (
    <div
      className={classNames(styles.slideContent, 'ion-padding', className)}
    >
      {textPosition && textPosition === TEXT_POSITION.TOP && children}
      {imageSrc && (
        <div
          className={styles.slideImage}
          style={{ backgroundImage: `url(${imageSrc})` }}
        />
      )}
      {textPosition && textPosition === TEXT_POSITION.BOTTOM && children}
    </div>
  );
};
