import styles from './LandingPage.module.scss';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  IonContent,
  IonPage,
  IonRouterLink,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { ImageBackground } from 'components/ImageBackground';
import Footer from 'components/Footer';
import * as Text from 'components/Text';
import logoLight from 'assets/large-logo-light.png';
import appleStoreBadge from 'assets/apple-store-badge.svg';
import googlePlayStoreBadge from 'assets/google-play-badge.svg';
import landing1Dark from 'assets/landing-1-dark.png';
import presentation1 from 'assets/presentation-1.png';
import presentation2 from 'assets/presentation-2.png';
import presentation3 from 'assets/presentation-3.png';
import trainer from 'assets/trainer-large.png';
import { AspectRatio } from 'components/AspectRatio';

const LadingPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('fullscreen');
    return () => {
      document.body.classList.remove('fullscreen');
    }
  }, []);

  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent>
        <div className={classNames(styles.page)}>
          <div className={styles.mainSection}>
            <div className={classNames(styles.container, styles.h100, 'd-flex direction-column size-5-padding-horizontal')}>
              <header className={styles.header}>
                <div>
                  <img src={logoLight} alt='' height='120' className={classNames(styles.logo, 'size-5-padding-top')} />
                </div>
                <nav className={'d-flex ion-align-items-center size-5-margin-vertical'}>
                  <IonRouterLink routerLink={'/trainer'} routerDirection={'root'} className={styles.navLink}>
                    <strong>{t('MEET YOUR TRAINER')}</strong>
                  </IonRouterLink>
                </nav>
              </header>
              <main className={'flex-1 d-flex direction-column position-relative'}>
                <AspectRatio expandOnFullScreen={false} ratio={'2000:1383'} className={classNames(styles.largeSectionImage, styles.sectionImage)}>
                  <ImageBackground src={landing1Dark} objectFit={'contain'} objectPosition={'bottom right'} />
                </AspectRatio>
                <IonGrid className={classNames(styles.h100, 'w-100 d-flex direction-column no-padding')}>
                  <IonRow className={classNames(styles.h100, 'flex-1 ion-align-items-center')}>
                    <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'no-padding')}>
                      <div>
                        <h1>
                          <Text.Huge>
                            <strong>
                              {t('Welcome to a healthy new you!')}
                            </strong>
                          </Text.Huge>
                        </h1>
                        <p className={'size-9-margin-bottom'}>
                          <Text.Body>
                            {t('Workout from home, or even from the office.')}
                            <br />
                            {t('Tips & tricks to reach your goals quicker.')}
                            <br />
                            {t('No more excuses!')}
                          </Text.Body>
                        </p>

                        <div className={'size-9-padding-bottom'}>
                          <h2>
                            <Text.BiggerAndBold>
                              {t('Download Now for Free')}
                            </Text.BiggerAndBold>
                          </h2>
                          <div>
                            <a className={classNames(styles.storeLink, 'size-5-margin-end')} href={process.env.REACT_APP_APPLE_STORE_URL || ''}>
                              <img src={appleStoreBadge} alt={'Download on the App Store'} />
                            </a>
                            <a className={styles.storeLink} href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL || ''}>
                              <img src={googlePlayStoreBadge} alt={'Get it on Google Play'} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'ion-align-self-end centered no-padding')}>
                      <AspectRatio expandOnFullScreen={false} ratio={'1106:1383'} className={classNames(styles.sectionImage)}>
                        <ImageBackground className={classNames(styles.darkTheme)} src={landing1Dark} objectFit={'contain'} objectPosition={'bottom'} />
                      </AspectRatio>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </main>
            </div>
          </div>

          <div className={styles.midSection}>
            <div className={classNames(styles.container, 'd-flex direction-column size-10-padding-top size-5-padding-horizontal')}>
              <h2 className={'ion-text-center size-8-padding-bottom'}>
                <Text.XXL>
                  <strong>{t('Why Cornelius?')}</strong>
                </Text.XXL>
              </h2>

              <IonGrid className={classNames(styles.h100, 'w-100 no-padding')}>
                <IonRow className={classNames(styles.h100, 'size-8-padding-top size-8-padding-bottom')}>
                  <IonCol size={'12'} size-md={4} push-md={8} className={classNames(styles.h100, 'ion-align-self-center no-padding')}>
                    <div className={classNames(styles.sectionText, 'size-6-padding-start')}>
                      <h3>
                        <Text.XXL>
                          {t('Train anytime, anywhere')}
                        </Text.XXL>
                      </h3>
                      <p>
                        <Text.Body>
                          {t('Daily workout routines in the palm of your hand.')}
                          <br />
                          {t('Start using the Cornelius app on any of your devices right now.')}
                        </Text.Body>
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size={'12'} size-md={8} pull-md={4} className={classNames(styles.h100, 'no-padding')}>
                    <AspectRatio expandOnFullScreen={false} ratio={'1450:1108'}>
                      <ImageBackground className={classNames(styles.sectionImage)} src={presentation1} objectFit={'contain'} />
                    </AspectRatio>
                  </IonCol>
                </IonRow>

                <IonRow className={classNames(styles.h100, 'size-8-padding-top size-8-padding-bottom')}>
                  <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'ion-align-self-center no-padding')}>
                    <div className={classNames(styles.sectionText, 'size-6-padding-end')}>
                      <h3>
                        <Text.XXL>
                          {t('Eat healthy, stay energized')}
                        </Text.XXL>
                      </h3>
                      <p>
                        <Text.Body>
                          {t('Quick and delicious recipes meant to keep you fit and energised.')}
                          <br />
                          {t('We know time is precious, and we are here to help.')}
                          <br />
                          {t('Get the right nutrients, while saving time to enjoy the rest of your day.')}
                        </Text.Body>
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'no-padding')}>
                    <AspectRatio expandOnFullScreen={false} ratio={'1339:1106'}>
                      <ImageBackground className={classNames(styles.sectionImage)} src={presentation2} objectFit={'contain'} />
                    </AspectRatio>
                  </IonCol>
                </IonRow>

                <IonRow className={classNames(styles.h100, 'size-8-padding-top size-8-padding-bottom')}>
                  <IonCol size={'12'} size-md={6} push-md={6} className={classNames(styles.h100, 'ion-align-self-center no-padding')}>
                    <div className={classNames(styles.sectionText, 'size-6-padding-start')}>
                      <h3>
                        <Text.XXL>
                          {t('Tips & tricks')}
                        </Text.XXL>
                      </h3>
                      <p>
                        <Text.Body>
                          {t('Get ahead of the game, see quicker results without compromises.')}
                          <br />
                          {t('Take the smart route to achieving your health goals.')}
                        </Text.Body>
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size={'12'} size-md={6} pull-md={6} className={classNames(styles.h100, 'no-padding')}>
                    <AspectRatio expandOnFullScreen={false} ratio={'792:1108'}>
                      <ImageBackground className={classNames(styles.sectionImage, styles.tipsImage)} src={presentation3} objectFit={'contain'} />
                    </AspectRatio>
                  </IonCol>
                </IonRow>

                <IonRow className={classNames(styles.h100, styles.trainer)}>
                  <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'ion-align-self-center no-padding')}>
                    <div className={classNames('size-6-padding-end size-8-padding-vertical')}>
                      <h3>
                        <Text.XXL>
                          {t('Get a personal trainer,')}
                          <br />
                          {t('without leaving your home')}
                        </Text.XXL>
                      </h3>
                      <p>
                        <Text.Body>
                          {t('Get personalised advice and help.')}
                          <br />
                          {t('Our professionals are here to answer your questions.')}
                        </Text.Body>
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size={'12'} size-md={6} className={classNames(styles.h100, 'centered no-padding')}>
                    <AspectRatio expandOnFullScreen={false} ratio={'740:1108'} className={classNames(styles.sectionImage, styles.trainerImage)}>
                      <ImageBackground src={trainer} objectFit={'contain'} objectPosition={'bottom'} />
                    </AspectRatio>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>

          <div className={styles.bottomSection}>
            <div className={classNames(styles.container, 'd-flex direction-column size-5-padding-horizontal')}>
              <div className={classNames('size-8-padding-top', styles.appLinksAndLogo)}>
                <div>
                  <h2 className={'no-margin-top'}>
                    <Text.BiggerAndBold>
                      {t('Download Now for Free')}
                    </Text.BiggerAndBold>
                  </h2>
                  <div>
                    <a className={classNames(styles.storeLink, 'size-5-margin-end')} href={process.env.REACT_APP_APPLE_STORE_URL || ''}>
                      <img src={appleStoreBadge} alt={'Download on the App Store'} />
                    </a>
                    <a className={styles.storeLink} href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL || ''}>
                      <img src={googlePlayStoreBadge} alt={'Get it on Google Play'} />
                    </a>
                  </div>
                </div>
                <div className={classNames('centered', styles.bottomSectionLogo)}>
                  <img src={logoLight} alt='' height='120' className={classNames(styles.logo)} />
                </div>
              </div>
            </div>
          </div>
          <Footer isContained={true} />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default LadingPage;
