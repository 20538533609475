import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormatProps } from './types';

export const NumberFormat: React.FC<NumberFormatProps> = ({
  value,
  className,
  ...options
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  if (typeof value === 'undefined') return null;
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;

  return (
    <span className={className}>
      {new Intl.NumberFormat(language, options).format(parsedValue)}
    </span>
  );
};
