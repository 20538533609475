import React, { useState } from "react";
import {
  IonItem,
  IonCheckbox
} from "@ionic/react";
import * as Text from "components/Text";

export const CheckItem: React.FC<{
  className?: string;
}> = React.memo((
  {
    className,
    children
  }
) => {
  const [checked, setChecked] = useState(false);

  return (
    <IonItem className={className} lines={"none"}>
      <IonCheckbox className={"size-4-margin-vertical ion-align-self-start"} slot={"start"} color="light"
                   checked={checked} onIonChange={e => setChecked(e.detail.checked)}/>
      <Text.Body><span style={{ textDecoration: checked ? "line-through" : "none" }}>{children}</span></Text.Body>
    </IonItem>
  );
});
